.logRegLayout {
    display: flex;
    align-items: center;
    height: 100vh;
}

.logRegLayout .ltLgContainer {
    width: 500px;
    background: url(../../../../assets/images/loginBack.png);
    background-size: cover;
    color: white;
    height: 100%;
    display: flex;
    padding-top: 300px;
    /* align-items: center; */
    justify-content: flex-end;
}

.logRegLayout .ltLgContainer .logRegBtnCntnr {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logRegLayout .ltLgContainer .logRegBtnCntnr button {
    background: #c1c6ff;
    border-radius: 100px 0 0 100px;
    width: 100%;
    color: white;
    height: 50px;
    width: 160px;
    margin-bottom: 20px;
    font-weight: 600;
}

.logRegLayout .ltLgContainer .logRegBtnCntnr button.active {
    background: white;
    color: var(--darkFont);
}

.logRegLayout .rtLgContainer {
    width: calc(100vw - 500px);
    padding: 40px;
    height: 100vh;
    overflow: auto;
}

.logRegLayout .rtLgContainer .ftrCntnr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    position: absolute;
    width: calc(100vw - 600px);
    background: white;
    padding: 20px 0;
    bottom: 0;
    z-index: 99;
}

.logRegLayout .rtLgContainer .ftrCntnr a {
    color: black;
    font-weight: normal;
    font-size: 15px;
}

.logRegLayout .rtLgContainer .ftrCntnr .dvdr {
    color: #cdcfd8;
    margin: 0 15px;
}

.logRegLayout .rtLgContainer .ftrCntnr .cprtCnt {
    font-size: 15px;
    margin-bottom: 0px;
}

@media (max-width: 1460px) {
    .logRegLayout .rtLgContainer .ftrCntnr a {
        font-size: 13px;
    }

    .logRegLayout .rtLgContainer .ftrCntnr .cprtCnt {
        font-size: 13px;
    }
}

@media (max-width: 992px) {
    .logRegLayout .rtLgContainer .ftrCntnr {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .logRegLayout .rtLgContainer {
        width: calc(100vw - 275px);
        padding: 25px;
    }
    .logRegLayout .rtLgContainer .ftrCntnr {
        width: calc(100vw - 325px);
    }
    .logRegLayout .ltLgContainer {
        width: 275px;
    }
}

@media (max-width: 575px) {
    .logRegLayout .ltLgContainer .logRegBtnCntnr button:nth-child(2) {
        border-radius: 0 100px 100px 0px;
    }
    .logRegLayout .ltLgContainer .logRegBtnCntnr button.active {
        border: 1px solid #c1c6ff;
    }
    .logRegLayout .ltLgContainer .logRegBtnCntnr button {
        border-radius: 100px 0px 0px 100px;
        width: 150px;
    }
    .logRegLayout .ltLgContainer .logRegBtnCntnr {
        flex-direction: row;
        /* display: none; */
    }
    .login.regContainer .rgTypeWrpr label {
        width: 100%;
        margin-right: 0px !important;
        margin-bottom: 15px !important;
    }
    .login.regContainer .rgTypeWrpr {
        flex-wrap: wrap;
    }
    .logRegLayout .rtLgContainer {
        width: calc(100vw - 15px);
        overflow: unset;
        height: 100%;
    }
    .logRegLayout {
        /* background: url(../../../../assets/images/loginBack.png); */
        background-size: cover;
        flex-direction: column;
        height: 100%;
    }
    .logRegLayout .ltLgContainer {
        padding-top: 50px;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: unset;
    }
    .logRegLayout .rtLgContainer .ftrCntnr {
        width: 100%;
        left: 0;
        position: relative;
    }
}

@media (max-width: 425px) {
    .rgTypeWrpr {
        width: 100%;
        flex-direction: row;
    }
}
