.emptyCart {
    height: 58vh;
    display: flex;
    justify-content: center;
    align-content: center;
}

.emptyCart .emptyCartImg {
    margin: 15px auto;
}

.emptyCart h2 {
    margin: 10px 0;
    font-weight: 600;
    color: var(--primColor);
    font-size: 26px;
}

.emptyCart h4 {
    font-weight: 500;
    color: var(--secColor);
    font-size: 20px;
}

.emptyCart .primButton {
    margin: 35px auto;
    width: max-content;
}

@media (max-width: 600px) {
    .emptyCart h2 {
        font-size: 22px;
    }
    .emptyCart h4 {
        font-size: 18px;
    }
}
