.dashboard {
    background: url(../../assets/images/dashboardback.png);
    background-size: cover;
    background-position: bottom center;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: calc(100vh - 316px);
    background-attachment: fixed;
}

.dashboard .customBreadcrumbs {
    margin-bottom: 10px;
}

.dashboard .dashboardInner {
    background: #fff;
    padding: 30px 35px;
    margin-top: 5px;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000010;
}

.dashboard .dashboardRt .maTitle {
    padding: 15px 30px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000010;
    margin-bottom: 20px;
}

.sideNav .userAvatarProfile {
    background: #fff;
    padding: 35px 0 20px 0px;
    border-bottom: 1px solid #f1f3f6;
}

.sideNav ul {
    background: #fff;
    padding: 20px 0 10px 20px;
    border-radius: 15px;
}

.sideNav .userAvatarProfile .material-icons {
    font-size: 100px;
    color: #c7c7c7;
}

.sideNav .userAvatarProfile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
}

.sideNav .userAvatarProfile h4 {
    font-size: 18px;
    color: #373737;
    margin: 15px auto;
    word-break: break-all;
    padding: 0 10px;
}

.sideNav hr {
    margin-bottom: 25px;
    background-color: #f1f3f6;
}

.sideNav h5 {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
    text-align: start;
    padding-top: 15px;
}

.sideNav ul {
    padding-inline-start: 0;
    margin: 0;
}

.sideNav ul a,
.sideNav ul .MuiListItem-button {
    width: 100%;
    height: 100%;
    display: block;
    color: #979797 !important;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.sideNav ul .MuiListItem-button {
    height: 60px;
    padding: 0;
    /* border-top-left-radius: 30px; */
    /* border-bottom-left-radius: 30px; */
}

.sideNav ul a,
.sideNav ul .test {
    padding: 8px 30px;
    /* border-top-left-radius: 30px; */
    /* border-bottom-left-radius: 30px; */
    height: 100%;
}

.sideNav ul a span,
.sideNav ul .MuiListItem-button span {
    padding-inline-end: 10px;
}

.sideNav ul .MuiCollapse-wrapperInner {
    padding-inline-start: 35px;
}

.sideNav ul a:hover {
    text-decoration: none;
}

.sideNav ul > .MuiListItem-button a.active,
.sideNav ul .activeDropdown {
    color: white !important;
    background: var(--primColor);
    border-radius: 15px;
}

.sideNav ul .MuiListItem-button a.active {
    color: #fff;
}

.sideNav ul .MuiListItem-button {
    border-radius: 15px;
}

.sideNav ul .MuiList-root .MuiListItem-button a.active {
    color: var(--primColor) !important;
}

.dashboard .dashboardLt .gridListToggle button {
    color: var(--primColor);
    width: 50%;
}

.dashboard .dashboardLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.dashboard .dashboardLt .gridListToggle i {
    padding-inline-end: 10px;
}

.dashTitle {
    font-size: 20px;
    font-weight: 600;
    color: #0e131f;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dashTitle span {
    font-size: 12px;
    font-weight: 600;
    color: #999999;
    padding-inline-start: 0px;
    text-transform: initial;
    display: block;
}

.myAccount .accountCnt {
    background: #fff;
    border-radius: 5px;
    width: 100%;
}

.myAccount .accChild h4 {
    text-align: start;
    font-size: 18px;
    font-weight: 600;
    margin: 25px 0;
}

.myAccount .primButton,
.notifications .primButton {
    max-width: 200px;
    margin: 20px auto;
}

.myAccount .accountCnt .crActBtn .primButton {
    margin: 0 0 15px 0;
    min-width: 150px;
}

.myAccount .accountCnt .crActBtn .secButton {
    min-width: 150px;
}

.myAccount .userProfileImage {
    position: relative;
}

.myAccount .userProfileImage .noUserImage {
    font-size: 160px;
    color: #c7c7c7;
}

.myAccount .userProfileImage .editUserImage {
    width: 30px;
    height: 30px;
    min-width: auto;
    border-radius: 50%;
    background: var(--primColor);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25px;
    right: 20px;
    font-size: 18px;
    color: #fff;
    box-shadow: 0px 3px 6px rgba(25, 157, 229, 40%);
}

.mySavedCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.mySavedCards .emptySavedCard {
    border: 1px dashed #a6adb4;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
}

.mySavedCards .emptySavedCard h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #4d4d4d;
    font-weight: 600;
}

.mySavedCards .filledSavedCard {
    height: 183px;
    border-radius: 5px;
    background: url('../../assets/svg/cardBg.svg'),
        linear-gradient(#5a5a5a 0%, #323335 49.33%, #000 100%);
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 1rem 0.75rem;
    color: #fff;
}

.mySavedCards .modifyCard {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.mySavedCards .modifyCard .material-icons {
    font-size: 18px;
}

.mySavedCards .modifyCard.edit {
    background: #5d80ff9c;
    color: #fff;
}

.mySavedCards .modifyCard.delete {
    background: #ff5d5d9c;
    color: #fff;
    margin-inline-start: 10px;
}

.mySavedCards .card-num {
    padding: 26px 0 32px;
    text-align: center;
}

.mySavedCards .card-num span {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1;
    margin: 5px 0;
}

.mySavedCards .card-num span + span {
    margin-inline-start: 15px;
}

.mySavedCards .fs-12 {
    font-size: 0.75rem;
    text-align: start;
}

.mySavedCards .custom-modal .modal-dialog.modal-lg {
    max-width: 400px;
}

.auctionStatus {
    display: none;
}

.auctionStatus .MuiListItem-root {
    padding: 0;
    width: max-content;
    margin-bottom: 25px;
    border-radius: 50px;
    min-width: max-content;
}

.auctionStatus .MuiListItem-root a {
    padding: 10px 25px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 50px;
    color: #000;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.auctionStatus .MuiListItem-root a:focus,
.auctionStatus .MuiListItem-root a:hover {
    text-decoration: none;
}

.auctionStatus .MuiListItem-root:not(:last-child) {
    margin-inline-end: 16px;
}

.auctionStatus .MuiListItem-root a.active {
    color: var(--primColor);
    background: rgb(25 157 229 / 12%);
    border-color: rgb(25 157 229);
}

.companyProfile .MuiPaper-root.MuiAppBar-root {
    color: #333;
    box-shadow: 0px 2px 2px #ccc;
    background: #f7f7f7;
    margin-top: 20px;
}

.companyProfile .MuiPaper-root.MuiAppBar-root .MuiTab-root.Mui-selected {
    color: var(--primColor);
    background: #199de517;
}

.companyProfile .MuiPaper-root.MuiAppBar-root .MuiTabs-indicator {
    background: var(--primColor);
}

.companyProfile .MuiBox-root {
    padding-top: 30px;
}

.companyProfile .catgDet > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.companyProfile .catgDet .customCheckbox {
    width: 25%;
}

.dashboard .walletHead {
    margin-bottom: 30px;
}

.dashboard .walletBalance {
    background: rgb(25 157 229 / 11%);
    border: 1px dashed var(--primColor);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--primColor);
    text-align: start;
    margin-inline-end: auto;
    width: max-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard .walletBalance .material-icons {
    padding-inline-end: 5px;
}

.dashboard .walletBalance span:last-child {
    padding-inline-start: 10px;
}

.dashboard h5 {
    font-size: 18px;
    text-align: start;
    margin-bottom: 20px;
    color: var(--secColor);
}

.dashboard .notifications .dashSubtitle,
.dashboard .mySavedSearches .dashSubtitle {
    font-size: 16px;
    color: #727272;
    line-height: 24px;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 400;
}

.dashboard .mySavedSearches table thead tr th:last-child {
    text-align: center;
}

.dashboard .mySavedSearches table .ssActBtn button:first-child {
    color: var(--primColor);
}

.dashboard .mySavedSearches table .ssActBtn button:last-child {
    color: #ff6c6c;
}

.prflImgWrpr {
    position: relative;
    width: 170px;
    height: 170px;
    margin: auto;
}

.prflImgWrpr .prflImg {
    width: 170px;
    height: 170px;
    border-radius: 200px;
    object-fit: cover;
}

.prflImgWrpr .edtPrflBtn {
    min-width: unset;
    background: var(--primColor);
    border-radius: 100px;
    color: white;
    position: absolute;
    bottom: 0;
    right: 13px;
    height: 40px;
    width: 40px;
}

.myAccount .savedAddressCnt,
.myAccount .savedCardsCnt {
    text-align: left;
}

.myAccount .savedAddressCnt .savedAddressCard,
.myAccount .savedCardsCnt .savedCardsCard {
    width: auto;
    height: auto;
    background: rgb(234 242 255);
    border: 1px solid #e0e0e0;
    padding: 15px 20px;
    border-radius: 5px;
    position: relative;
}

.myAccount .savedAddressCnt .savedAddressCard h4,
.myAccount .savedAddressCnt .savedAddressCard address,
.myAccount .savedCardsCnt .savedCardsCard h4 {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
}

.myAccount .savedAddressCnt .savedAddressCard address {
    margin: 0;
}

/* .myAccount .savedAddressCnt .savedAddressCard button,
.myAccount .savedCardsCnt .savedCardsCard button {
    float: right;
} */

.myAccount .savedAddressCnt .savedAddressCard .MuiButton-label,
.myAccount .savedCardsCard .MuiButton-label {
    text-decoration: underline;
}

.myAccount .savedAddressCnt > a,
.myAccount .savedCardsCnt > a {
    font-size: 16px;
    color: #000;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 600;
    color: var(--primColor);
}

.myAccount .savedAddressCnt .savedAddress,
.myAccount .savedCardsCnt .savedCards {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
}

.addNewButton.btn {
    border: 1px solid var(--primColor);
}

.myAccount .savedAddressCnt .savedAddressCard .dfltCard {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: center;
    background: var(--primColor);
    color: white;
    border-radius: 100px 0 0 100px;
    padding: 5px 10px;
    font-size: 14px;
}

.dashboardInner.myAuctions input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    white-space: pre-line;
    position: relative;
    top: -1px;
}

.dashboardInner.myAuctions input::-moz-placeholder {
    /* Firefox 19+ */
    white-space: pre-line;
    position: relative;
    top: -1px;
}

.dashboardInner.myAuctions input:-ms-input-placeholder {
    /* IE 10+ */
    white-space: pre-line;
    position: relative;
    top: -1px;
}

.dashboardInner.myAuctions input:-moz-placeholder {
    /* Firefox 18- */
    white-space: pre-line;
    position: relative;
    top: -1px;
}

.dashboardInner.myAuctions .searchMiscFilters .customSelect {
    margin-inline-start: 0px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.dashboardInner.myAuctions .searchMiscFilters .customSelect label {
    white-space: pre;
    margin-bottom: 0px;
    margin-right: 10px;
    background: #fff;
    padding: 0 0px;
}

.dashboardInner.myAuctions .customInput .MuiInputBase-root,
.dashboardInner.myAuctions .customSelect .MuiInputBase-root {
    border: 1px solid #dddddd;
    background: white;
    width: max-content;
}

.dashboardInner.myAuctions .customInput .MuiInputBase-root {
    width: auto;
    min-width: 120px;
}

.dashboardInner .bdngWrpr {
    max-width: 250px;
}

.dashboardInner .bdngWrpr .gridTimer {
    display: flex;
    justify-content: flex-start !important;
}

.dashboardInner .bdngWrpr .gridTimer.openTimer,
.dashboardInner .bdngWrpr .gridTimer.openTimer p {
    color: #e47245;
    font-size: 14px;
}

.dashboardInner .bdngWrpr .gridTimer p,
.dashboardInner .bdngWrpr .gridTimer h6 {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    color: gray;
}

.dashboardInner .bdngWrpr .gridTimer h6 {
    margin-right: 6px;
}

.dashboardInner .bdngColumn {
    width: 420px;
}

.dashboardInner .addTempBtn {
    color: var(--primColor);
    background: #f6f7fe;
    padding: 10px 20px;
    border-radius: 4px;
}

.dashboardInner .addTempBtn .material-icons-outlined,
.dashboardInner .addTempBtn .material-icons {
    margin-right: 5px;
}

.dashboardInner .addTempBtn .MuiButton-label {
    text-transform: none;
}

.subsptWrapper .plnInfoList {
    list-style: none;
    padding-left: 0;
}

.subsptWrapper .plnInfoList li {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
    color: gray;
}

.subsptWrapper .plnInfoList li label {
    width: 120px;
    font-weight: 500;
    margin: 0;
}

.subsptWrapper .plnInfoList .plnName {
    color: var(--secColor);
    font-weight: 500;
}

.subsptWrapper .plnInfoList .changePlan {
    color: var(--darkFont);
    font-size: 12px;
    margin: 0;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.subsptWrapper .plnInfoList .changePlan span.material-icons-outlined {
    font-size: 19px;
    margin-right: 6px;
}

.subsptWrapper .plnInfoList .plnStus {
    display: flex;
    align-items: center;
}

.subsptWrapper .plnInfoList .plnStus .material-icons {
    font-size: 10px;
    margin-left: 6px;
    color: green;
}

.subsptWrapper .plnInfoList .plnStus.inactive .material-icons {
    font-size: 10px;
    margin-left: 6px;
    color: rgb(235, 41, 41);
}

.subsptWrapper .acrdBtn {
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.subsptWrapper .acrdBtn .material-icons {
    margin-left: 10px;
}

.subsptWrapper .plnDtls {
    text-align: left;
}

.subsptWrapper .nxtPyInfo {
    color: gray;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.subsptWrapper .nxtPyInfo .material-icons {
    font-size: 18px;
    margin-right: 7px;
}

.subsptWrapper .seeSubPlan {
    color: var(--darkFont);
    font-size: 14px;
    text-decoration: underline;
}

.dashboardInner.auctionTemplates .loadingCnt,
.dashboardInner.userManagement .loadingCnt,
.dashboardInner .loadingCnt {
    grid-template-columns: 1fr;
}

.adTempltPopup .MuiDialog-paperFullScreen {
    background: url(../../assets/images/dashboardback.png);
    background-size: cover;
    background-position: bottom center;
    background-attachment: fixed;
}

.fullScreenPopup.adTempltPopup .MuiAppBar-colorPrimary {
    background-color: transparent !important;
    box-shadow: none;
    position: relative;
}

.fullScreenPopup.adTempltPopup .MuiAppBar-colorPrimary .MuiButtonBase-root {
    position: fixed;
    top: 20px;
    right: 20px;
}

.fullScreenPopup.adTempltPopup .MuiAppBar-colorPrimary .MuiIconButton-label {
    color: black;
}

.fullScreenPopup.adTempltPopup .MuiAppBar-colorPrimary .MuiTypography-root {
    font-size: 24px;
    color: var(--darkFont);
    text-transform: uppercase;
    margin-bottom: -50px;
    font-weight: 600;
    text-align: center;
}

.fullScreenPopup.adTempltPopup .modalChildren .addUserModal {
    background: white;
    padding: 45px 30px;
    border-radius: 10px;
    box-shadow: 0 3px 35px #00000006;
    max-width: 1020px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    position: relative;
}

.fullScreenPopup.adTempltPopup .modalChildren .actionButton button {
    min-width: 120px;
}

.fullScreenPopup.adTempltPopup .modalChildren .actionButton.loca .secButton {
    margin-right: 5px;
}

.saved-card {
    position: relative;
}

.saved-card .sc-btns-wrap {
    position: absolute;
    top: 5px;
    right: 5px;
}

.saved-card .sc-btns-wrap .MuiIconButton-root {
    color: #9bffe4;
}

.saved-card .sc-btns-wrap .dltBtn.MuiIconButton-root {
    color: #ffb7b7;
}

.saved-card .sc-btns-wrap .defaultBtn.MuiIconButton-root {
    color: #a3c2ff;
}

.dashboardInner.mySavedCards .savedAddressCard {
    background: whitesmoke;
    padding: 13px;
    border-radius: 5px;
}

.dashboardInner.mySavedCards .savedAddressCard h4 {
    text-align: left;
    font-size: 14px;
}

.dashboardInner.mySavedCards .savedAddressCard h4 span {
    color: gray;
    padding-bottom: 8px;
}

.emptySavedCard.adBnkCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emptySavedCard.adBnkCard h6 {
    margin-top: 14px;
}

@media (max-width: 1024px) {
    .respSort {
        display: none;
    }

    .auctionStatus {
        display: flex;
    }

    .dashboard .searchMiscFilters {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 15px;
    }

    .dashboard .myWallet .dpTable .table,
    .dashboard .mySavedSearches .dpTable .table {
        table-layout: auto;
    }

    .dashboard .myWallet .dpTable .table td,
    .dashboard .mySavedSearches .dpTable .table td {
        min-width: 130px;
        font-size: 14px;
    }

    .dashboard .mySavedSearches .dpTable .table td:first-child {
        min-width: 60px;
    }

    .dashboard .myWallet .dpTable .table th,
    .dashboard .mySavedSearches .dpTable .table th {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 14px;
        height: 40px;
    }

    .fullScreenPopup.adTempltPopup .MuiAppBar-colorPrimary .MuiTypography-root {
        font-size: 16px;
        margin-bottom: unset;
        max-width: 270px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }

    .mySavedCards {
        row-gap: 25px;
    }
}

@media (max-width: 545px) {
    .auctionStatus {
        flex-wrap: wrap;
    }

    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        height: 40px;
        font-size: 15px;
        line-height: 1.2;
    }

    .dashTitle {
        font-size: 18px;
    }

    .dashboard .dashboardRt .maTitle {
        padding: 5px 15px;
    }

    .dashboard .myAuctions,
    .dashboard .myInvoices,
    .dashboard .mySavedCards,
    .dashboard .myAccount,
    .dashboard .myWallet,
    .dashboard .notifications,
    .dashboard .mySavedSearches {
        padding: 20px 15px;
    }

    .myAccount .userProfileImage .noUserImage {
        font-size: 120px;
    }

    .myAccount .accChild h4 {
        font-size: 16px;
    }

    .myAccount .primButton,
    .notifications .primButton {
        max-width: 100%;
        margin: 10px auto 20px;
    }

    .dashboard .walletBalance {
        width: 100%;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 10px;
    }

    .dashboard .walletBalance span:last-child {
        margin-left: auto;
    }
}

@media (max-width: 500px) {
    .subsptWrapper .d-flex h4 {
        margin-bottom: 15px !important;
    }

    .subsptWrapper .d-flex {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }

    .dashboard .notifications .dashSubtitle,
    .dashboard .mySavedSearches .dashSubtitle {
        font-size: 14px;
    }

    .subsptWrapper .plnInfoList li label {
        width: 100%;
    }

    .subsptWrapper .plnInfoList .plnName {
        margin-right: 10px !important;
    }

    .subsptWrapper .plnInfoList .changePlan {
        margin-left: 0;
    }

    .subsptWrapper .plnInfoList li {
        flex-wrap: wrap;
    }

    .dashboard .dashboardInner {
        padding: 20px 15px;
    }
}

@media (max-width: 470px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 15px;
        height: 40px;
        font-size: 13px;
        line-height: 1.4;
    }
}

@media (max-width: 420px) {
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }

    .auctionStatus .MuiListItem-root {
        margin-bottom: 5px;
    }

    .auctionStatus {
        margin-bottom: 10px;
    }
}

@media (max-width: 385px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        font-size: 13px;
    }

    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }

    .auctionStatus {
        flex-wrap: wrap;
    }
}

@media (max-width: 340px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 12px;
    }

    .auctionStatus .MuiListItem-root {
        margin-bottom: 15px;
    }
}
