header.mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 999;
}

header.mainHeader {
    padding: 10px 0;
    background: #fff;
    height: 100px;
    border-top: 15px solid var(--primColor);
}

header .headLt img {
    max-width: 230px;
    width: auto;
    height: auto;
}

header.homeHeader .headLt img {
    max-width: 310px;
    margin-bottom: -80px;
}

.languageMenu img {
    padding-inline-end: 10px;
}

header .headRt ul li:not(:last-child) {
    margin-inline-end: 30px;
}

/* header .headRt ul li:nth-last-child(2) {
    margin-right: 10px;
} */

header .headRt ul li .secButton buttonl,
header .headRt ul li .tertButton buttonl {
    min-width: 100px;
}

header .headRt ul li.headCart {
    margin-inline-end: 0;
}

header .headRt ul li.headCart > button .MuiBadge-badge {
    color: var(--primColor);
    background-color: #ffffff !important;
    border: 1px solid var(--primColor);
}

header .headRt ul li button.MuiButton-text .MuiButton-label {
    text-transform: none;
}

header .headRt ul li button.MuiButton-text .MuiButton-label img {
    width: 24px;
    margin-right: 10px;
}

header .headRt ul li a {
    color: #2e2d2d;
    font-size: 15px;
    font-weight: 400;
    padding: 0 0.5rem;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

header .headLt .headerSearchForm {
    background: #fff;
    border-radius: 25px;
    height: 45px;
    width: 320px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-inline-start: 20px;
}

header .headLt .headerSearchForm input {
    border: none;
    margin-inline-end: 0;
    height: inherit;
    background: inherit;
    border-radius: 25px;
    /* border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    padding: 12px 25px 12px 25px;
    color: #848484;
    width: -webkit-fill-available;
}

header .headLt .headerSearchForm button {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secColor);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    min-width: 45px;
    margin-inline-end: 0px;
}

header .headLt .headerSearchForm input:focus,
header .headLt .headerSearchForm button:focus {
    border: none;
    box-shadow: none;
}

header .headLt .headerSearchForm input::placeholder {
    color: #848484;
}

header .headRt ul li a.active,
header .headRt ul li a:hover {
    color: var(--secColor);
    font-weight: 500;
}

header .headRt ul li span.material-icons {
    color: var(--primColor);
    font-size: 25px;
}

.categoryHeader {
    height: 45px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

.categoryHeader ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
}

.categoryHeader ul .MuiListItem-root {
    min-width: max-content;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
}

.categoryHeader ul .MuiListItem-root .material-icons-round {
    padding-right: 8px;
    margin-bottom: 3px;
}

.categoryHeader ul .MuiListItem-root:first-child {
    color: var(--primColor);
}

.allCategoriesDrawer ul {
    padding: 0;
    margin: 0;
}

.allCategoriesDrawer .acdHead {
    padding: 10px 16px;
    padding-right: 0;
    margin-bottom: 20px;
    background: var(--primColor);
}

.allCategoriesDrawer .acdHead button {
    color: #ccc;
}

.allCategoriesDrawer .MuiPaper-root {
    width: 100%;
    max-width: 300px;
}

.allCategoriesDrawer .acdHead h4 {
    color: #fff;
    margin: 0;
    letter-spacing: 0.5px;
    font-size: 18px;
}

.respNav,
.respNavBtn {
    display: none !important;
}

.searchSlide {
    padding-top: 20px;
}

.searchSlide h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
}

.searchSlide h3 span {
    margin-inline-start: 10px;
    font-size: 15px;
    text-transform: capitalize;
    color: #808080;
    font-weight: 600;
}

.searchSlide .searchHeaderInput {
    height: 50px;
}

.searchSlide .searchHeaderInput input {
    height: inherit;
}

.searchSlide .searchHeaderInput input:focus {
    box-shadow: none;
    border-color: var(--primColor);
}

.searchSlide .searchHeaderInput .input-group-prepend {
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    z-index: 100;
    background: #fff;
    color: var(--primColor);
    padding: 0;
    border: none;
}

.searchSlide .searchHeaderInput .input-group-prepend .MuiButton-label {
    height: 100%;
}

.searchSlide .searchHeaderInput .input-group-prepend .input-group-text {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    border: none;
    display: flex;
    justify-content: center;
    background: #fff;
    color: var(--primColor);
}

.popularCatg .catgType label h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    width: 100%;
    color: #313131;
    text-align: center;
    margin-top: 10px;
    line-height: 22px;
}

.popularCatg .catgType .iconCnt {
    background: #efefef;
    padding: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popularCatg .catgType label span {
    font-size: 32px;
    color: #848484;
}

.popularCatg .catgType label .MuiListItem-button {
    height: 100%;
    flex-wrap: wrap;
}

.searchSlide h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
    color: #717171;
    margin-top: 30px;
}

.popularCatg .catgType {
    width: 33%;
    height: 90px;
    margin-bottom: 15px;
}

.popularCatg .catgType label {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px dashed #777;
}

.popularCatg .catgType input:checked ~ label {
    background: var(--primColor);
    color: #fff;
    border: 1px dashed var(--primColor);
}

.popularCatg .catgType input:checked ~ label h4 {
    color: #fff;
}

.popularCatg .catgType input:checked ~ label span {
    color: #ffc6bb;
}

.popularCatg .catgType input:checked ~ label .iconCnt {
    background: #ca2d0f;
}

.popularCatg .catgType label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-inline-end: 10px;
    -webkit-transition: 250ms all ease-in-out;
    -o-transition: 250ms all ease-in-out;
    transition: 250ms all ease-in-out;
}

.headerDrawer .languageSwitch a > img {
    padding-inline-end: 12px;
}

.respHeaderSearch .headerSearchForm {
    flex-wrap: nowrap;
    padding: 10px 15px;
}

.respHeaderSearch .headerSearchForm input {
    width: 100%;
    width: 100%;
    border-radius: 25px;
    background: var(--accentColor);
    height: 50px;
    padding-inline-start: 20px;
    border-color: transparent;
}

.respHeaderSearch .headerSearchForm button {
    min-width: max-content;
    background: var(--accentColor);
    margin-inline-start: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: var(--primColor);
}

/* .headerDropMenu .MuiPopover-paper {
    top: 100px !important;
    right: 30px;
    left: initial !important;
} */

/* .headerDropMenu.rtl .MuiPopover-paper {
    right: initial;
    left: 30px !important;
} */

.langDropMenu .MuiPopover-paper {
    top: 40px !important;
    left: initial !important;
    right: 30px;
    width: 150px;
}

.langDropMenu.rtl .MuiPopover-paper {
    right: initial;
    left: 30px !important;
}

.headerDropMenu .MuiButtonBase-root {
    color: #6f6f6f;
    padding: 10px 15px;
}

.headerDropMenu .MuiButtonBase-root:hover,
header .headRt .headerDropMenu ul li span.material-icons {
    color: #6f6f6f;
}

.headerDropMenu .MuiButtonBase-root .material-icons,
.headerDropMenu ul li span.material-icons {
    padding-inline-end: 10px;
}

.headerDropMenu .MuiPopover-paper .MuiBadge-badge,
header .headRt .headerDropMenu .MuiBadge-badge {
    right: 15px;
    border: 1px solid #fff;
}

header .headRt .headerDropMenu ul li:not(:last-child) {
    margin-inline-end: 0;
}

.userProfileIcon {
    width: 30px;
    height: 30px;
    border-radius: 100px;
}

.notiDropMenu .MuiPaper-root.MuiPopover-paper {
    min-width: 380px;
    height: 320px;
}

.notiDropMenu .MuiPaper-root.MuiPopover-paper .ntiHdr {
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(243, 243, 243);
    color: var(--primColor);
}

.notiDropMenu .MuiPaper-root.MuiPopover-paper .ntiHdr h4 {
    color: var(--primColor);
    font-weight: 600;
}

.notiDropMenu .MuiPaper-root.MuiPopover-paper .ntiHdr span {
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 100px;
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.notiDropMenu .MuiPaper-root.MuiPopover-paper .notiList {
    list-style: none;
    padding-left: 0;
    height: 259px;
    overflow-y: auto;
    margin-bottom: 0;
    background: whitesmoke;
}

.notiDropMenu .MuiPaper-root.MuiPopover-paper .notiList li {
    padding: 15px;
}

.notiDropMenu .MuiPaper-root.MuiPopover-paper .notiList li.newNoti {
    background: white;
    color: var(--primColor);
    border-bottom: 1px solid rgb(243, 243, 243);
}

.notiDropMenu .emtNoti {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.notiDropMenu .emtNoti .material-icons {
    margin-bottom: 10px;
    font-size: 28px;
    color: gray;
}

.notiDropMenu .emtNoti h4 {
    color: gray;
}

.lgRegCntnr {
    margin-left: 30px;
}

.lgRegCntnr img {
    width: 24px;
    margin-right: 10px;
}

.upgrdPup .material-icons {
    font-size: 35px;
    color: var(--secColor);
}

/* RESPONSIVE */

@media (max-width: 1145px) {
    header .headRt ul li:not(:last-child) {
        margin-inline-end: 5px;
    }
    header .headRt ul li a {
        font-size: 15px;
    }
    header .headRt li > button {
        min-width: max-content;
        margin-inline-end: 5px;
    }
}

@media (max-width: 1024px) {
    .headRt.respNav .naLogoHead a img {
        width: 140px;
    }
    header .headLt img {
        max-width: 110px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .deskNav {
        display: none !important;
    }
    .respNav {
        display: block !important;
    }
    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: #000 !important;
    }
    .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-weight: 600;
        /* justify-content: space-between; */
    }
    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-weight: 600;
        flex-wrap: wrap;
    }

    .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .headRt.respNav .navRespLinks a.active,
    .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .headRt.respNav .naLogoHead {
        padding: 20px 0px 20px 30px;
    }
    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .mobShopCurrLocation {
        padding-bottom: 0 !important;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    /* .headRt.respNav button {
        justify-content: space-between;
    } */

    .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }
    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }
    header {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 100;
        box-shadow: 0px 3px 10px #cccccc61;
    }
}

@media (max-width: 992px) {
    header.homeHeader .headLt img {
        max-width: 200px;
        margin-bottom: 0;
    }
}

@media (max-width: 500px) {
    .searchSlide h3,
    .searchSlide h4 {
        font-size: 16px;
    }
    .searchSlide h3 span {
        margin-inline-start: 10px;
        font-size: 13px;
    }

    .popularCatg .catgType label h4 {
        font-size: 14px;
    }
    header.mainHeader {
        height: 70px;
    }
    header.mainHeader .headLt img {
        max-width: 120px;
    }
}

/* ******* */
