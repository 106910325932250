.subsWrapper {
    padding-top: 60px;
    padding-bottom: 60px;
}

.subsTitle {
    color: var(--primColor);
    font-size: 30px;
    font-weight: 600;
}

label.colorPrimary {
    color: var(--primColor);
}

.subsWrapper .tmSwtBox label {
    font-weight: 500;
    font-size: 20px;
}

.subsWrapper .onbdrTost {
    font-weight: 500;
    text-align: left;
    margin-top: 5px;
}

.subsWrapper .tmSwtBox .MuiSwitch-root {
    padding: 6px;
}

.subsWrapper .tmSwtBox .MuiSwitch-track {
    background-color: var(--primColor);
    border-radius: 100px;
    opacity: 1;
}

.SubsTableWrpr {
    display: grid;
    grid-template-columns: 400px 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    overflow: auto;
}

.SubsTableWrpr .fstColmn {
    background: #f6f7ff;
}

.SubsTableWrpr .colWrpr div {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.SubsTableWrpr .fstColmn div:nth-child(even) {
    background: #f1f2ff;
}

.SubsTableWrpr .plnColmn div:nth-child(even) {
    background: #f6f7ff;
}

.SubsTableWrpr .colWrpr .tblHdrVw {
    height: 120px;
}

.SubsTableWrpr .colWrpr .tblHdrVw h2 {
    font-family: 'Katibeh', cursive;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.SubsTableWrpr .colWrpr .tblHdrVw h3 {
    color: var(--darkFont);
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}

.SubsTableWrpr .colWrpr .tblHdrVw .plnTlDiv {
    height: unset;
}

.SubsTableWrpr .colWrpr .tblHdrVw .plnTlDiv .secButton {
    margin: 10px 0 20px;
}

.SubsTableWrpr .colWrpr .ntAvlBtn {
    color: #ff7777;
}

.SubsTableWrpr .colWrpr .avlBtn {
    color: #50d64c;
}

.subsWrapper .onbrdInfo {
    font-size: 15px;
}

.SubsTableWrpr .cntLnk {
    color: white;
    background: var(--primColor);
    padding: 7px 28px;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 25px;
}

.orderReviewBox {
    border: 1px dashed gray;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}

.orderReviewBox h5 {
    margin-bottom: 20px;
}

.orderReviewBox ul {
    list-style: none;
    padding-left: 0;
    min-width: 300px;
}

.orderReviewBox ul .divider {
    border-top: 1px solid rgb(202, 202, 202);
    margin-top: 10px;
    padding-bottom: 10px;
}

.orderReviewBox ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderReviewBox .rtImg img {
    width: 120px;
    filter: brightness(0.8);
}

.orderReviewBox.bronze {
    border: 1px dashed #cd7f32;
    background: #fffaf4;
}

.orderReviewBox.silver {
    border: 1px dashed gray;
    background: #f5f5f5;
}

.orderReviewBox.silver .primButton {
    width: 200px;
    margin: 0 auto;
}

.orderReviewBox.silver img {
    filter: grayscale(1);
}

.activeTag {
    padding: 4px 30px;
    background: #a3aaf0;
    color: #fff;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    width: min-content;
    margin: 0 auto;
    font-size: 13px;
}

.plnColmn.colWrpr.active {
    box-shadow: 0px 0px 30px #a3aaf04d;
}

.orderReviewBox.gold {
    border: 1px dashed #d4af37;
    background: #fff9e6;
}

.orderReviewBox.gold img {
    filter: brightness(1.1);
}

.orderReviewBox.enterprise {
    border: 1px dashed var(--primColor);
    background: #f6f7ff;
}

.orderReviewBox.enterprise img {
    filter: invert(0.8);
}

.subPayPopup .selectAddress label {
    border: 1px dashed gray;
    border-radius: 5px;
    width: 100%;
}

.subPayPopup .selectAddress {
    width: 100%;
}

.subPayPopup .selectAddress .savedAddressCard {
    padding-left: 20px;
    padding-right: 50px;
}

.subPayPopup .selectAddress .savedAddressCard div h5 {
    font-size: 15px;
}

.subPayPopup .selectAddress .savedAddressCard .edtDlBtns {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.subPayPopup .selectAddress .savedAddressCard .edtDlBtns .MuiIconButton-root {
    padding: 4px;
}

.subPayPopup .chCntGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
}

@media (max-width: 600px) {
    .SubsTableWrpr {
        grid-template-columns: 100% 50% 50% 50% 50%;
    }
}
