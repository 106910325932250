.login {
    max-width: 500px;
    margin: 60px auto;
    border-radius: 5px;
    padding: 45px;
}

.login .brandLogo {
    width: 250px;
    height: auto;
    object-fit: contain;
}

.login h2 {
    margin: 25px 0 20px;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--primColor);
}

.login h4 {
    margin-bottom: 45px;
}

.login form {
    border-top: 1px solid #b59fdf;
    padding-top: 45px;
    margin-top: 45px;
}

.login form .primButton button {
    background: var(--darkFont);
    border: 1px solid var(--darkFont);
}

.login .loginActBox .MuiButtonBase-root.MuiListItem-root {
    padding-inline-start: 0;
}

.login .loginActBox .customCheckbox label {
    margin-bottom: 0;
}

.login .loginActBox {
    margin-bottom: 15px;
    margin-top: -15px;
}

.login .loginActBox .primButton {
    width: 160px;
}

.login .loginMiscAction {
    margin-top: 40px;
    margin-bottom: 0;
}

.login .loginMiscAction div {
    margin-bottom: 10px;
}

.login .loginMiscAction div p {
    font-weight: normal;
}

.login .loginMiscAction div a {
    color: var(--primColor);
}

.login .MuiDivider-root {
    margin: 25px 0;
}

.rstPwdWrp {
    max-width: 400px;
    width: 100%;
}

@media (max-width: 992px) {
    .login {
        padding: 25px;
    }
}

@media (max-width: 767px) {
    .logRegLayout .rtLgContainer {
        width: calc(100vw - 350px);
    }
    .login {
        margin: 15px auto;
    }
}

@media (max-width: 575px) {
    .login .loginMiscAction div a {
        color: #141c65;
    }
}

@media (max-width: 500px) {
    .login {
        border: none;
        margin: 30px auto;
        padding: 30px 0;
        margin-top: 10px;
        padding-top: 10px;
    }
    .login h2 {
        font-size: 24px;
    }
    .login h4 {
        font-size: 16px;
    }
    .login .brandLogo {
        width: 150px;
    }
    .login .loginActBox {
        flex-wrap: wrap;
    }
    .login .loginActBox .primButton {
        width: 100%;
    }
}

@media (max-width: 445px) {
    .login .loginMiscAction {
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 14px;
    }
    .logRegLayout .rtLgContainer {
        width: calc(100vw - 30px);
    }
    .login form {
        padding-top: 35px;
        margin-top: 35px;
    }
    .login {
        padding: 0px;
    }
    .login .loginMiscAction a {
        font-size: 13px;
    }
}

@media (max-width: 380px) {
    .logRegLayout .rtLgContainer {
        width: calc(100vw - 10px);
    }
    .login h2 {
        font-size: 22px;
        margin-bottom: 5px;
    }
}
