.dashboardInner.reportManagement .dashSubtitle {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 40px;
}

.dashboardInner.reportManagement .reportAction .reportLabel {
    min-width: 150px;
}

.dashboardInner.reportManagement .reportAction h6 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
}

.dashboardInner.reportManagement .reportAction {
    margin-top: 30px;
}

.dashboardInner.reportManagement .reportAction .reportRange {
    border-left: 2px solid #ccc;
    padding-left: 20px;
    height: 80px;
}

.dashboardInner.reportManagement .reportAction .reportRange .customSelect,
.dashboardInner.reportManagement .reportAction .datePicker .customDatepicker {
    margin-bottom: 0;
}

.dashboardInner.reportManagement .actionButton {
    margin-top: 40px;
}

.dashboardInner.reportManagement .actionButton .primButton {
    width: 200px;
}

.adTempltPopup .rprtDisplay {
    background: white;
    padding: 45px 40px;
    border-radius: 10px;
    box-shadow: 0 3px 35px #00000006;
    margin-inline-start: auto;
    margin-inline-end: auto;
    position: relative;
}
