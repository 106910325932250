html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: var(--primFont), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor);
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorHelper {
    cursor: help;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--secColor);
    font-weight: 500;
}

.customContainer {
    padding-inline-start: 30px !important;
    padding-inline-end: 30px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--primFont), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 25px;
    /* width: 100%; */
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiInputBase-root,
.customSelect .MuiInputBase-root,
.customDatepicker .MuiInputBase-root,
.customTextArea .MuiInputBase-root {
    background: #f6f6f6;
}

.customInput .MuiOutlinedInput-notchedOutline,
.customSelect .MuiOutlinedInput-notchedOutline,
.customDatepicker .MuiOutlinedInput-notchedOutline,
.customTextArea .MuiOutlinedInput-notchedOutline {
    border: none;
}

.customInput .MuiFormLabel-root,
.customSelect .MuiFormLabel-root {
    color: #727272;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--primColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--primColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primColor) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--poppins) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1300 !important;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 280px;
    width: 100%;
    margin-inline-end: 25px;
}

.search .searchLt {
    position: sticky;
    top: 10px;
}

.adTempltPopup.aucListingModal {
    z-index: 1250 !important;
}

.dashboard .dashboardLt .sideNav {
    position: sticky;
    top: 10px;
}

.search .searchRt {
    width: 100%;
}

.dashboard .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.search .searchResults.Grid,
.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.search .searchMiscFilters .customSelect,
.search .searchMiscFilters .customInput,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 25px;
}

.moveBack {
    color: #a6adb4 !important;
}

.moveBack .MuiButton-label {
    text-transform: none;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-inline-end: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

.dpTable table {
    color: #212529;
}

.dpTable table thead th {
    background: #f0f0f0;
    color: var(--textColor);
    font-weight: 500;
}

.dpTable table .MuiFormControlLabel-root {
    margin: 0;
}

.dpTable table td.credit {
    color: #4caf50;
    font-weight: 500;
    display: flex;
}

.dpTable table td.debit {
    color: #f44336;
    font-weight: 500;
    display: flex;
}

.dpTable table td > .material-icons {
    padding-inline-end: 5px;
}

.dpTable .table th {
    border: none;
}

.dpTable .table td,
.dpTable .table th {
    vertical-align: middle;
}

.searchSkeletonBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.searchSkeletonBody > div {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.material-icons.rtl,
.rtlImage {
    transform: rotateY(180deg);
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

/* .searchCnt .loadingCnt {
    display: block;
} */

/* .searchCnt .loadingCnt .listViewSkeleton {
    height: 60vh;
} */

/* .searchCnt .loadingCnt .listViewSkeleton .skeletonWrapper {
    min-height: 150px;
} */

.userInitials.small {
    width: 30px;
    height: 30px;
    font-size: 14px;
    background: #e1f4ff;
    border-radius: 50%;
    color: #1d4963;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInitials.large {
    width: 90px;
    height: 90px;
    font-size: 28px;
    font-weight: 700;
    background: #f1f3f6;
    border-radius: 50%;
    color: #9ca8b9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.shippingModal .shippingModalInner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 15px;
}

.shippingModal .shippingModalInner {
    width: 100%;
    max-width: 500px !important;
}

.shippingModal .shippingModalInner > .MuiButtonBase-root {
    background: #eaeaea;
    border-radius: 10px;
    padding: 15px;
}

.shippingModal .shippingModalInner > .MuiButtonBase-root p {
    margin: 0;
}

.shippingModal .shippingModalInner > .MuiButtonBase-root h4 {
    font-weight: 700;
}

.MuiDialogContent-root .modalCloseBtn {
    min-width: max-content;
    position: absolute;
    top: 12px;
    right: 20px;
}

.saved-card.default .defaultCard {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: #fbfbfb;
    padding: 5px 10px;
    color: #333;
    border-radius: 35px;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 3px 20px #89898987;
}

.customBidPopup h5 {
    font-size: 16px;
}

.customBidPopup .lowBidWarning {
    background: #ffeac3;
    padding: 15px;
    font-size: 15px !important;
    text-align: left;
    border-radius: 8px;
    color: #93531d;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.customBidPopup .lowBidWarning .material-icons {
    margin-right: 10px;
}

@media (max-width: 1145px) {
    .search .searchLt,
    .dashboard .dashboardLt {
        max-width: 250px;
    }

    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-inline-start: 15px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: rgb(25 157 229 / 11%);
        border-color: rgb(25 157 229 / 20%) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }
}

input[value='']::-webkit-datetime-edit {
    color: transparent;
}

input:focus::-webkit-datetime-edit {
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* @media (max-width: 991px) {
    .customContainer {
        padding-inline-start: 30px !important;
        padding-inline-end: 30px !important;
    }
} */

.favoriteCheck {
    background: transparent !important;
}

.favoriteCheck label,
.favoriteCheck label {
    color: var(--primColor) !important;
}

.MuiDrawer-root.MuiDrawer-modal.rightDrawer {
    z-index: 1290 !important;
}

.capitalize {
    text-transform: capitalize;
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }
}

@media (max-width: 700px) {
    .pagination-wrapper {
        align-items: center !important;
    }

    .pagination-wrapper h6 {
        margin-bottom: 0 !important;
    }
}

@media (max-width: 600px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }

    .MuiList-root.MuiMenu-list .MuiButtonBase-root.MuiMenuItem-root {
        white-space: pre-wrap;
    }

    .pagination-wrapper h6 {
        margin-bottom: 15px !important;
        width: 100%;
    }
}

@media (max-width: 445px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }

    .pagination-wrapper h6 {
        font-size: 14px;
    }
}

@media (max-width: 425px) {
    .pagination-wrapper {
        justify-content: center !important;
    }

    .pagination-wrapper h6 {
        /* padding-bottom: 15px; */
    }
}

@media print {
    .noPrint {
        display: none !important;
    }
}
