.filterPanel {
    position: sticky;
    top: 15px;
}

/* GRID */
.productCardGrid {
    width: 100%;
    height: 100%;
    text-align: start;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    border-radius: 5px;
}

.productCardGrid .pcgImg {
    width: 100%;
    height: 220px;
    position: relative;
}

.productCardGrid .pcgImg .favoriteCheck,
.productCardList .pclImg .favoriteCheck {
    position: absolute;
    top: 0;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgba(255, 70, 70, 10%);
}

.productCardGrid .pcgImg .favoriteCheck label,
.productCardList .pclImg .favoriteCheck label {
    color: var(--primColor) !important;
}

.productCardGrid .pcgImg .favoriteCheck input:checked + label,
.productCardList .pclImg .favoriteCheck input:checked + label {
    color: var(--primColor) !important;
}

.productCardGrid .primButton.small button,
.productCardGrid .secButton.small button {
    font-size: 11px;
    min-width: max-content;
    width: 100%;
    padding: 5px;
}

/* .productCardList .listBidStatus {
    padding-right: 30px;
} */

.productCardGrid .productWinningStatus,
.productCardList .productWinningStatus {
    padding: 10px 30px;
    bottom: 15px;
    color: #fff;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 15px;
}

.productCardGrid .productWinningStatus.winning,
.productCardList .productWinningStatus.winning {
    background: #e3ffe3;
    color: green;
}

.productCardGrid .productWinningStatus.won,
.productCardList .productWinningStatus.won {
    background: #e3ffe3;
    color: green;
}

.productCardGrid .productWinningStatus.outbid,
.productCardList .productWinningStatus.outbid {
    color: var(--secColor);
    background: #fff2ec;
}

.productCardGrid .productWinningStatus.lost,
.productCardList .productWinningStatus.lost {
    background: #d62f2f;
}

.productCardGrid .reserveNotMet,
.productCardList .reserveNotMet {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 55px;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgba(114, 142, 255, 90%);
    color: #fff;
    cursor: pointer;
}

.productCardGrid .productWinningStatus:nth-child(1) {
    top: 20px;
}

.productCardGrid .pcgImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    cursor: pointer;
}

.productCardGrid .gridProdTitle {
    font-size: 16px;
    color: var(--primColor);
    font-family: var(--primFont);
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* height: 4.5ex; */
}

.productCardGrid .gridLotInfo .gridDivider {
    padding: 0 10px;
}

.productCardGrid .gridLotDetails p {
    font-size: 13px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 6ex;
}

.productCardGrid .gridTimer {
    font-size: 15px;
    font-family: var(--primFont);
    color: #96989c;
    margin-bottom: 5px;
}

.productCardGrid .gridTimer .material-icons {
    padding-inline-end: 5px;
}

.productCardGrid .gridTimer h6 {
    margin: 0;
    font-size: 12px;
    padding-inline-end: 5px;
}

.productCardGrid .gridTimer p {
    margin: 0;
    /* color: var(--primColor); */
}

.productCardGrid .gridBidInfo {
    margin-top: 5px;
}

.productCardGrid .gridBidInfo h6 {
    margin-bottom: 0;
    font-size: 15px;
    color: #373737;
}

.productCardGrid .gridBidInfo p {
    margin-bottom: 0;
    color: var(--secColor);
    font-size: 24px;
}

.productCardGrid .gridMiscInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.productCardGrid .gridMiscInfo p {
    color: #646464;
    font-size: 16px;
    padding-right: 10px;
    margin: 0;
}

.productCardGrid .gridMiscInfo .material-icons {
    font-size: 18px;
    color: #646464;
    margin-bottom: 5px;
}

.productCardGrid .gridMiscInfo .gridDivider {
    padding: 0 10px;
}

.productCardGrid .gridAucInfo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 28.33px;
}

.productCardGrid .gridAucInfo p [class^='material-'] {
    padding-right: 5px;
}

.productCardGrid .gridAucInfo p {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: var(--primFont);
    color: #96989c;
    margin-bottom: 5px;
    min-width: 110px;
}

.productCardGrid .gridAucInfo .adrsInfoCrd {
    font-size: 15px;
    font-family: var(--primFont);
    color: #96989c;
    margin-bottom: 5px;
}

.productCardGrid .gridTimer [class^='material-'],
.productCardGrid .gridAucInfo [class^='material-'] {
    margin-right: 5px;
}

.productCardGrid .gridAucInfo .addrsLoc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 5.5ex;
}

.productCardGrid .gridAucInfo p:first-child {
    padding-right: 10px;
}

.productCardGrid .gridBidBox {
    margin-top: 15px;
    height: 9ex;
}

.productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
    width: 100%;
    margin-inline-start: 0;
}

.productCardGrid .gridBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
    font-size: 12px;
}

.productCardGrid .gridBidBox .biddingCnt .primButton,
.productCardGrid .gridBidBox .biddingCnt .secButton {
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .primButton:only-child,
.productCardGrid .gridBidBox .biddingCnt .secButton:only-child {
    width: 100%;
}

/* .productCardGrid .gridBidBox .biddingCnt.beforeLogin .primButton {
    width: 100%;
} */

/* GRID END */

/* AUCTION CARD */

.auctionCard {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 20px #0000001f;
}

.auctionCard .auctionCardLt {
    width: 80%;
}

.auctionCard .acImg {
    width: 100%;
    max-width: 220px;
    position: relative;
    margin-inline-end: 20px;
    position: relative;
}

.auctionCard .acOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--darkFont);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    text-align: center;
}

.auctionCard .acOverlay span {
    margin-top: 0;
}

.auctionCard .acOverlay h2 {
    font-size: 100px;
    margin: 0;
    width: 100%;
    line-height: 90px;
}

.auctionCard .acOverlay h4 {
    font-size: 25px;
    margin: 0;
    width: 100%;
}

.auctionCard .acOverlay h5 {
    font-size: 16px;
    margin: 0;
    width: 100%;
    text-align: center;
    color: #fff;
}

.auctionCard .acImg img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.auctionCard .acContent {
    margin-inline-end: 15px;
    text-align: start;
    width: 100%;
}

.auctionCard .acActBtn {
    width: 20%;
}

.auctionCard .acContent h2 {
    font-size: 20px;
    line-height: 30px;
    color: var(--secColor);
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.auctionCard .acContent p {
    font-size: 15px;
    color: #96989c;
    line-height: 24px;
    margin-bottom: 15px;
}

.auctionCard .acContent p.acAddress {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.auctionCard .acContent .acMiscInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.auctionCard .acContent .acMiscInfo:not(:last-child) {
    margin-bottom: 15px;
}

.auctionCard .acContent .acMiscInfo p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 15px;
    color: #96989c;
    width: 180px;
}

.auctionCard .acContent .acMiscInfo p span {
    padding-right: 10px;
}

.auctionCard .acContent .acMiscInfo > span {
    color: #646464;
    font-size: 15px;
}

.auctionCard .acActBtn .acTimer h6 {
    display: flex;
    align-items: center;
    color: var(--secColor);
    margin-bottom: 0;
    width: 100%;
    justify-content: center;
    text-transform: capitalize;
}

.auctionCard .acActBtn .acTimer h6 span {
    padding-inline-end: 10px;
    font-size: 33px;
}

.auctionCard .acActBtn .acTimer p {
    color: var(--secColor);
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
}

/* AUCTION CARD END */

/* LIST */

.productCardList {
    background: #fff;
    padding: 15px 0;
    padding-top: 28px;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #dddddd;
}

.productCardList .psListLt {
    width: auto;
    margin-inline-end: 30px;
}

.productCardList .listActBtn {
    width: 35%;
    margin-left: auto;
}

.productCardList .psListLt .listContent .listProdTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: left;
}

.productCardList .psListLt .allTmrWrp {
    display: flex;
}

.productCardList .psListLt .allTmrWrp p {
    font-size: 14px;
    color: #646464;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 20px;
}

.productCardList .psListLt .allTmrWrp .listTimer {
    color: var(--secColor);
}

.productCardList .psListLt .allTmrWrp .listTimer label {
    margin: 0;
    margin-right: 5px;
    font-size: 14px;
}

.productCardList .psListLt .allTmrWrp .listTimer h4 {
    font-size: 14px;
    margin-bottom: 0px;
}

.productCardList .psListLt .allTmrWrp .listTimer .material-icons {
    font-size: 22px;
    margin-right: 5px;
}

.productCardList .listBidInfo {
    margin-top: 15px;
}

.productCardList .listBidInfo h6 {
    margin: 0;
    margin-right: 5px;
}

.productCardList .listBidInfo .txtLabel {
    color: gray;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
}

.productCardList .listBidInfo p {
    margin-bottom: 0;
}

.productCardList .txtLabel {
    min-width: 120px;
    max-width: 120px;
    width: 100%;
}

.productCardList .vwBdHtr {
    color: var(--primColor);
    padding-left: 10px;
    font-size: 12px;
}

.productCardList .bpriceValue {
    font-weight: 500;
    font-size: 15px;
}

.productCardList .listBidBox .biddingCnt .primButton,
.productCardList .listBidBox .biddingCnt .secButton {
    margin-left: 10px;
    min-width: 150px;
}

.productCardList .tlTipWpr .MuiSvgIcon-root {
    color: #d2cbcb;
}

/* LIST END */

/* CART ITEM */

.cartItem {
    background: #fff;
    padding: 10px;
    padding-bottom: 5px;
    margin-bottom: 2px;
    border-bottom: 1px solid #ebebeb;
    padding-right: 0;
    padding-left: 0;
}

.cartItem .itemInfo {
    width: 75%;
}

.cartItem .pclImg {
    width: 100%;
    max-width: 150px;
    position: relative;
    margin-inline-end: 26px;
}

.cartItem .pclImg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

.cartItem .listContent {
    width: max-content;
    text-align: start;
    margin-inline-end: 20px;
    align-items: flex-start;
}

.cartBtn.listActBtn {
    width: auto;
    margin-left: auto;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle {
    font-size: 16px;
    font-weight: 500;
    color: #0e131f;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
    flex-wrap: wrap;
}

.cartItem .listContent .listLotInfo h5 {
    flex-wrap: wrap;
}

.cartItem .listContent .listLotInfo label {
    width: 100%;
}

.cartItem .listContent .listLotInfo h5 {
    color: #797979;
    font-size: 15px;
    margin: 0;
    line-height: 25px;
    display: flex;
}

.cartItem .listContent .listLotInfo h5:nth-child(2) {
    padding-inline-start: 20px;
    border-left: 1px solid #ccc;
    margin-inline-start: 20px;
}

.cartItem .listContent .listLotInfo span:first-child {
    width: 90px;
    display: inline-block;
}

.cartItem .listContent .listLotInfo span:last-child {
    color: black;
    font-size: 14px;
    font-weight: 600;
    /* padding-inline-start: 5px; */
    display: inline-block;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-inline-end: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-inline-end: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartBtn .removeCart {
    text-transform: initial;
    color: #de4747;
    margin-top: 5px;
    width: 100%;
}

.cartItem .updateCartQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
}

.cartItem .updateCartQuantity .customInput .MuiFormControl-root .MuiOutlinedInput-root {
    width: 120px;
    padding: 5px 35px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border-radius: 50px;
    text-align: center;
    height: 40px;
}

.cartItem .updateCartQuantity input:focus {
    outline: 0;
}

.cartItem .updateCartQuantity input {
    padding: 0;
    text-align: center;
}

.cartItem .updateCartQuantity button {
    min-width: max-content;
    position: absolute;
    top: 3px;
    z-index: 10;
    color: var(--primColor);
}

.cartItem .updateCartQuantity button:first-child {
    left: 10px;
    border-radius: 50px;
}

.cartItem .updateCartQuantity button.Mui-disabled {
    color: #ccc;
}

.cartItem .updateCartQuantity button:last-child {
    right: 10px;
    border-radius: 50px;
}

/*cart item new change*/

.npsCartWrpr .pclImg img {
    width: 36px;
    height: 36px;
}

.cartItem .npsCartWrpr .pclImg {
    width: 36px;
    height: 36px;
}

.cartItem .itemInfo.npsCartWrpr {
    width: 100%;
    position: relative;
}

.listActBtn .MuiButton-label {
    font-size: 13px;
    width: 120px;
    line-height: 17px;
}

.cartItem .listContent {
    margin-inline-end: 0;
}

/* CART ITEM END */

/* SIMILAR ITEMS */
.similarItem {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
}

.similarItem .pclImg {
    width: 25%;
    margin-inline-end: 30px;
}

.similarItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.similarItem .listContent {
    text-align: start;
    width: 70%;
}

.similarItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
    margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
    margin: 15px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
    width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
    width: 100%;
    height: 100%;
    max-width: 350px;
}

.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

/* .bidHistoryModal .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
} */

/* .bidHistoryModal table thead tr th:first-child,
.bidHistoryModal table tbody tr td:first-child {
    width: 55%;
} */

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
    text-align: start;
    white-space: nowrap;
}

.customModal .bidHistoryModal .modal-dialog.modal-lg {
    max-width: 550px;
}

.productCardGrid .moreInfo button,
.productCardList .moreInfo button {
    color: var(--secColor);
    text-transform: initial;
}

.productCardGrid .moreInfo button .material-icons,
.productCardList .moreInfo button .material-icons {
    padding-inline-start: 5px;
}

/*AuctionGridCard*/
.auctionCardGrid {
    padding: 10px;
    max-width: 455px;
    width: 100%;
    box-shadow: 0 3px 20px #0000001f;
}

.auctionCardGrid .auctionCardLt {
    min-width: 185px;
}

.auctionCardGrid .auctionCardRt {
    width: 100%;
    padding-left: 20px;
}

.auctionCardGrid .acTimer {
    color: #646464;
}

.auctionCardGrid .acTimer .material-icons {
    font-size: 38px;
}

.auctionCardGrid .acImg {
    width: 100%;
    max-width: 185px;
    height: 205px;
    position: relative;
    margin-inline-end: 20px;
    position: relative;
}

.auctionCardGrid .acOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 53, 148, 80%);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    text-align: center;
}

.auctionCardGrid .acOverlay span {
    margin-top: 20px;
}

.auctionCardGrid .acOverlay h2 {
    font-size: 100px;
    margin: 0;
    width: 100%;
    line-height: 90px;
}

.auctionCardGrid .acOverlay h4 {
    font-size: 25px;
    margin: 0;
    width: 100%;
}

.auctionCardGrid .acOverlay h5 {
    font-size: 16px;
    margin: 0;
    width: 100%;
}

.auctionCardGrid .acImg img {
    width: 100%;
    height: 205px;
    object-fit: cover;
}

.auctionCardGrid .auctionCardBtm .acContent .acMiscInfo {
    display: flex;
    align-items: center;
    margin: 15px 0;
    width: 100%;
}

.auctionCardGrid .auctionCardBtm .acContent .acMiscInfo p {
    margin: 0;
    display: flex;
    align-items: center;
    min-width: 40%;
    color: #96989c;
}

.auctionCardGrid .auctionCardBtm .acContent .acMiscInfo span {
    color: #646464;
}

.auctionCardGrid .auctionCardBtm .acContent .acMiscInfo p .material-icons {
    margin-right: 10px;
}

.auctionCardGrid .acTtl {
    font-size: 24px;
    color: var(--secColor);
    text-transform: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo h5 .mblLbl {
    display: none;
}

.productCardList .listBidInfo {
    margin-bottom: 10px;
}

.productCardList .listContent .listProdTitle {
    margin-bottom: 15px;
}

.pdtDscTxt {
    border: 1px solid rgb(189, 189, 189);
    padding: 5px;
    font-size: 13px;
    border-radius: 4px;
    line-height: 17px;
    margin-top: 5px;
}

.auctionCard .acActBtn .acTimer.upcomingAuction h6,
.auctionCard .acActBtn .acTimer.upcomingAuction p {
    color: var(--secColor);
}

.auctionCard .acActBtn .acTimer.activeAuction h6,
.auctionCard .acActBtn .acTimer.activeAuction p {
    color: #109010;
}

.auctionCard .acActBtn .acTimer.closedAuction h6,
.auctionCard .acActBtn .acTimer.closedAuction p {
    color: #e31f1f;
}

.productCardList .psListLt .allTmrWrp .listTimer.upcomingSlot h4,
.productCardList .psListLt .allTmrWrp .listTimer.upcomingSlot span {
    color: var(--secColor);
}

.productCardList .psListLt .allTmrWrp .listTimer.activeSlot h4,
.productCardList .psListLt .allTmrWrp .listTimer.activeSlot span {
    color: #109010;
}

.productCardList .psListLt .allTmrWrp .listTimer.closedSlot h4,
.productCardList .psListLt .allTmrWrp .listTimer.closedSlot span {
    color: #e31f1f;
}

@media (max-width: 991px) {
    .productCardList {
        flex-wrap: wrap;
    }
}

@media (max-width: 810px) {
    .auctionCard .acContent .acMiscInfo {
        align-items: flex-start;
    }
    .productCardList .listActBtn {
        width: 100%;
    }
    .auctionCard {
        flex-wrap: wrap;
    }

    .auctionCard .auctionCardLt {
        width: 100%;
    }

    .auctionCard .acActBtn {
        width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .cartItem {
        flex-wrap: wrap;
        width: 100%;
    }

    .cartItem .itemInfo {
        width: 100%;
        position: relative;
    }

    .cartItem .npsCartWrpr .pclImg {
        max-width: 150px;
        width: 100%;
        max-height: 150px;
        height: 100%;
        margin-inline-end: 0;
    }

    .cartItem .pclImg img {
        height: 150px;
        width: 150px;
    }

    .cartItem .listContent {
        width: 100%;
        margin-inline-end: 0;
        flex-wrap: wrap;
        margin-left: 0;
    }

    .cartItem .listActBtn {
        width: 100%;
    }

    .auctionCard .acActBtn .acTimer h6 span {
        padding-inline-end: 10px;
        font-size: 22px;
    }

    .auctionCard .acTimer {
        justify-content: center;
    }

    .cartItem .listContent .listProdTitle.col-4,
    .cartItem .listContent .listLotInfo.col-2,
    .cartItem .listActBtn.col-2 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .cartItem .listActBtn {
        position: absolute;
        top: 0;
        right: 0;
        width: max-content;
    }

    .cartItem .itemInfo.npsCartWrpr {
        width: 100%;
    }

    .cart .actnGrp .tblTtle {
        display: none;
    }

    .cartItem .listContent .listLotInfo h5 .mblLbl {
        display: block;
        color: gray;
        font-weight: bold;
        min-width: 98px;
    }
}

@media (max-width: 645px) {
    .productCardGrid .gridBidInfo,
    .productCardGrid .gridLotDetails p {
        height: auto;
    }

    .gridViewSkeleton {
        max-width: none;
    }

    .auctionCard .acImg {
        min-height: 270px;
    }
}

@media (max-width: 600px) {
    .cartItem .listActBtn {
        position: relative;
        text-align: left;
    }

    .cartItem button.removeCart {
        justify-content: flex-start;
        width: max-content;
        margin-top: 0;
    }

    .auctionCard .acContent .acMiscInfo p {
        min-width: 151px;
        max-width: 151px;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .auctionCard {
        flex-wrap: wrap;
    }

    .auctionCard .auctionCardLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .auctionCard .acImg {
        max-width: initial;
        margin-inline-end: 0;
    }

    .auctionCard .acContent {
        width: 100%;
        margin-inline-end: 0;
    }

    .auctionCard .acActBtn {
        width: 100%;
    }

    .auctionCard .acTimer {
        flex-wrap: nowrap !important;
        justify-content: flex-start !important;
        margin: 10px 0;
    }

    .auctionCard .acActBtn .acTimer h6 {
        justify-content: flex-start;
        width: max-content;
    }

    .auctionCard .acActBtn .acTimer p {
        margin: 0;
        margin-inline-start: 10px;
    }

    .auctionCard .acContent h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .auctionCard .acContent p {
        font-size: 13px;
        margin-bottom: 2px;
    }

    .cartItem .pclImg img {
        height: 150px;
    }

    .cartItem .itemInfo {
        width: 100%;
    }

    .productCardGrid .gridProdTitle {
        font-size: 16px;
    }

    .productCardGrid .gridTimer h6,
    .productCardGrid .gridBidInfo h6 {
        font-size: 13px;
    }

    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        width: 100%;
    }

    .productCardGrid .gridBidBox .customInput,
    .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }

    .productCardGrid .gridBidBox .customInput input {
        height: 100%;
    }

    .productCardGrid .gridBidBox .primButton,
    .productCardGrid .gridBidBox .secButton {
        width: 49%;
    }

    .similarItem .listContent .listProdTitle {
        font-size: 15px;
    }

    .similarItem .pclImg {
        width: 30%;
        margin-inline-end: 10px;
    }

    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }

    .cartItem .listContent .listLotInfo h5 {
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 450px) {
    .auctionCard .acContent h2 {
        font-size: 17px;
        margin-top: 15px;
    }

    .auctionCard .acContent p {
        font-size: 12px;
    }

    .auctionCard .acContent {
        margin-bottom: 10px;
    }
}

.adCaledarDiv {
    margin-top: 15px;
    padding-right: 20px;
}

.adCaledarDiv label small {
    font-weight: 500;
}

.adCaledarDiv img {
    height: 30px;
    width: 100%;
}

.adCaledarDiv a {
    margin-left: 15px;
    max-width: 80px;
}

/* @media (min-width: 1440px) {
  .productCardGrid {
    width: 24%;
  }
} */

@media (max-width: 600px) {
    .productCardList .psListLt {
        width: 100%;
        margin-inline-end: 0;
    }

    .productCardGrid .productWinningStatus,
    .productCardList .productWinningStatus {
        margin-top: 15px;
    }
}

@media (max-width: 475px) {
    .productCardList .listActBtn {
        width: 100%;
    }

    .productCardList .listBidStatus {
        padding-right: 0;
    }
}

@media (max-width: 400px) {
    .productCardList .psListLt .allTmrWrp {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .productCardList .psListLt .allTmrWrp p,
    .productCardList .psListLt .allTmrWrp .listTimer {
        text-align: left;
        flex-wrap: nowrap !important;
        padding-bottom: 10px;
    }

    .biddingCnt {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .listActBtn .MuiButton-label {
        width: 100%;
    }

    .productCardList .listBidBox .biddingCnt .primButton,
    .productCardList .listBidBox .biddingCnt .secButton {
        margin-left: 0px;
        min-width: 100%;
    }
}
