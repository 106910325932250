.productView.standalonePage {
    background: #eeeeff;
    padding: 40px;
}

.productView.standalonePage .pdtContainer {
    max-width: 1078px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.productView.standalonePage .pdtContainer .bgBckWrp {
    background: white;
    border-radius: 10px;
    padding: 30px;
    width: 100%;
}

.productView.standalonePage .pdtContainer .bgBckWrp.bdHstWrp {
    max-width: 310px;
    margin-left: 10px;
    padding: 25px;
}

.productView.standalonePage .pdtContainer .pdtDtlCntnr h2 {
    font-size: 20px;
    color: var(--primColor);
    font-weight: 600;
    margin-bottom: 20px;
}

.productView.standalonePage .pdtContainer .dscpData {
    font-weight: 400;
    font-size: 15px;
    color: #646464;
    margin-bottom: 40px;
}

.productView.standalonePage .pdtContainer .grdCntnr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.productView.standalonePage .pdtContainer .grdCntnr label {
    color: #646464;
    max-width: 130px;
    width: 100%;
    font-size: 15px;
}

.productView.standalonePage .pdtContainer .grdCntnr p {
    font-size: 15px;
}

.productView.standalonePage .pdtContainer .grdCntnr .prcAmount {
    color: var(--primColor);
    font-weight: 700;
    font-size: 18px;
}

.productView .pvTimerBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--secColor);
    margin-top: 30px;
    margin-bottom: 35px;
}

.productView .pvTimerBox > * {
    margin-bottom: 0;
}

.productView .pvTimerBox label {
    padding-inline-start: 10px;
    padding-inline-end: 15px;
    font-size: 15px;
}

.productView .pvTimerBox .material-icons {
    margin-right: 7px;
    font-size: 22px;
}

.productView .pvTimerBox h4 {
    font-size: 15px;
    font-weight: 500;
}

.productView .bdInfoTxt {
    color: #a0a0a0;
    font-size: 14px;
    margin-top: 25px;
}

.productView .bdngContainer {
    max-width: 370px;
    width: 100%;
}

.productView .bdngContainer .primButton button {
    max-width: 203px;
}

.productView .bdHstWrp h4 {
    font-size: 16px;
    font-weight: 600;
}

.productView .bdHstWrp .bidHistoryTable table.table-bordered {
    position: relative;
}

.productView .bdHstWrp .bidHistoryTable table.table-bordered .badgeIcon {
    color: var(--primColor);
    position: absolute;
    left: -22px;
    top: 4px;
}

.productView .bdHstWrp .bidHistoryTable table.table-bordered,
.productView .bdHstWrp .bidHistoryTable table.table-bordered td,
.productView .bdHstWrp .bidHistoryTable table.table-bordered th {
    border: none;
    font-size: 14px;
    padding: 3px;
    font-weight: 500;
}

.productView .bdHstWrp .bidHistoryTable table .bidAmountCol {
    text-align: right;
}

.productView .bdHstWrp .bidHistoryTable .pagination-wrapper h6 {
    font-size: 12px;
}

.productView .bdHstWrp .bidHistoryTable table.table-bordered tr td:first-child {
    color: gray;
}

.productView .pvLt,
.productView .pvRt {
    width: 100%;
}

.standalonePage.productView {
    text-align: left;
    background: #eeeeff;
    min-height: calc(100vh - 316px);
}

.standalonePage.productView .pvLt,
.standalonePage.productView .pvRt {
    width: 50%;
}

.standalonePage.productView .pvRt {
    padding-left: 45px;
}

.productView .image-gallery-thumbnails-container {
    text-align: start;
}

.productView .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

/* .standalonePage.productView .pvRt .bdngByWrpr {
    width: 600px;
} */

.productView .image-gallery-icon:hover {
    color: var(--primColor);
}

.productView .image-gallery-thumbnail {
    border: 2px solid transparent;
}

.productView .image-gallery-thumbnail.active,
.productView .image-gallery-thumbnail:hover,
.productView .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 5px;
}

.prfxBdngCnt .prfxCnt {
    height: 45px;
    padding: 0 20px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .productView .pvActBtn .pabChild {
    margin: 10px 0;
} */

.productView .pvActBtn {
    /* background: #f8f8f8; */
    padding: 15px 0px;
    /* margin-right: 30px; */
}

.bdngByWrpr .bdPlcWrpr:last-child {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.bdngByWrpr .bdPlcWrpr:last-child .primButton {
    margin-left: auto;
}

.bdngByWrpr .bdPlcWrpr .primButton,
.bdngByWrpr .bdPlcWrpr .secButton {
    width: 300px !important;
    margin-left: auto;
}

.productView .lctnWrpr .lctnDtls p.brdrHdr {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #ededed;
    padding-bottom: 5px;
}

.productView .pvBidBox {
    width: auto;
    /* margin: 20px auto 5px; */
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.productView .pvBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}

.productView .pvBidBox.bynwRpr .biddingCnt > div {
    margin: 0;
    width: 100%;
}

.productView .pvBidBox .biddingCnt .customInput {
    margin-bottom: 0px;
}

.productView .pvBidBox .customInput,
.productView .customInput .MuiTextField-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root,
.productView .customInput .MuiInputBase-root.MuiOutlinedInput-root input {
    height: 50px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    margin-top: 5px;
}

.productView .pvBidBox .customInput .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-top: 0px;
}

.productView .pvBidBox .tertButton {
    width: 100%;
    margin-inline-start: 10px;
}

.productView .pvBidBox .tertButton,
.productView .pvBidBox .tertButton button {
    height: 50px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton,
.productView .pvBidBox .primButton button,
.productView .pvBidBox .secButton button {
    height: 50px;
    font-weight: 700;
}

.productView .pvBidBox form {
    width: 100%;
    /* margin-left: 15px; */
}

.productView .pvBidBox form:not(:nth-child(1)) {
    margin-top: 30px;
}

.productView .pvActBtn .pabChild label {
    font-size: 15px;
    font-weight: 600;
    color: #373737;
    min-width: 120px;
    text-align: left;
    margin: 0;
}

.productView .pvActBtn .pabChild h4 {
    font-size: 18px;
    color: #373737;
    font-weight: 700;
    width: max-content;
    margin: 0;
}

.productView .pvActBtn .pabChild.pvCurrentBid h4 {
    color: var(--primColor);
}

.productView .pvBidHistBtn {
    color: var(--primColor);
    text-transform: initial;
    margin: 0px;
    margin-left: auto;
    font-weight: 600;
    display: flex;
}
.productView .pvBidHistBtn .MuiButton-label .material-icons {
    padding-inline-end: 10px;
}

.productView .pvBidBox > div {
    margin: 0;
    /* width: 49%; */
    margin-inline-end: 10px;
}

.productView .pvBidBox .primButton,
.productView .pvBidBox .secButton {
    width: 49%;
    margin: 0 auto;
}

.productView .pvProductInfo small {
    color: #646464;
}

.productView .pvProductInfo h2 {
    font-size: 24px;
    color: #0e131f;
    margin: 0;
    font-weight: 700;
    line-height: 34px;
}

.productView .pvProductDesc label {
    font-size: 15px;
    color: #373737;
    font-weight: 600;
    margin: 0;
    padding-inline-end: 10px;
}

.productView .pvProductDesc h6 {
    font-size: 15px;
    color: #646464;
    font-weight: 400;
    margin: 0;
    display: inline;
}

.productView .pvProductDesc .pvDescChild {
    margin-bottom: 10px;
}

.productView .cartHead {
    font-size: 24px;
    color: #353535;
    font-weight: 700;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
}

.productView .MuiPaper-root.MuiAccordion-root {
    margin-bottom: 10px;
    border: none !important;
    background: #f6f6f6 !important;
    color: #333333;
}

.productView .MuiAccordionSummary-content.Mui-expanded,
.productView .Mui-expanded .MuiIconButton-label .MuiSvgIcon-root {
    color: var(--primColor);
    fill: var(--primColor) !important;
}

.productView .MuiButtonBase-root.MuiAccordionSummary-root {
    margin-bottom: 0;
    background: inherit;
}

.productView .MuiAccordionDetails-root {
    font-size: 15px;
    padding-left: 17px;
    padding-right: 17px;
}

.productView .accTitle {
    margin-bottom: 0;
    font-weight: 600;
}

.productView .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
}

.productView .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 500px;
}

.productView .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto;
}

.productView .pvBidStatus {
    padding: 10px 0;
    background: #fff;
    position: sticky;
    width: 100%;
    top: -20px;
    z-index: 10;
}

.productView .pvBidStatus h4 {
    padding: 15px;
    color: #fff;
    height: 45px;
    width: 100%;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.25px;
    border-radius: 5px;
}

.productView .pvBidStatus h4.winning {
    background: #06a2b4;
}

.productView .pvBidStatus h4.won {
    background: #06b473;
}

.productView .pvBidStatus h4.outbid {
    background: #ff9472;
}

.productView .pvBidStatus h4.lost {
    background: #ff7272;
}

.productView .lctnWrpr {
    display: flex;
    align-items: center;
}

.productView .ldTag {
    background: var(--secColor);
    height: 35px;
    width: 177px;
    border-radius: 200px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.productView .ldTag p {
    font-size: 13px;
}

.productView .lctnWrpr .lctnDtls .MuiSvgIcon-root {
    margin-right: 10px;
}

.productView .lctnWrpr .lctnDtls p {
    color: #96989c;
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 10px;
}
.productView .lctnWrpr .lctnDtls span {
    color: #646464;
}

.productView .pvBidBox .pabChild label {
    min-width: 150px;
}

.actnDtlWrpr .acContent {
    margin-inline-end: 15px;
    text-align: start;
    width: 100%;
}

.actnDtlWrpr .acActBtn {
    width: 20%;
}

.actnDtlWrpr .acContent h2 {
    font-size: 20px;
    line-height: 30px;
    color: var(--darkFont);
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.actnDtlWrpr .acContent p {
    font-size: 15px;
    color: #96989c;
    line-height: 24px;
    margin-bottom: 15px;
}

.actnDtlWrpr .acContent .acMiscInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.actnDtlWrpr .acContent .acMiscInfo:not(:last-child) {
    margin-bottom: 15px;
}

.actnDtlWrpr .acContent .acMiscInfo p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 15px;
    color: #96989c;
    width: 180px;
    min-width: 180px;
}

.actnDtlWrpr .acContent .acMiscInfo p span {
    padding-right: 10px;
}

.actnDtlWrpr .acContent .acMiscInfo > span {
    color: #646464;
    font-size: 15px;
}

.liveAuction .actnTmrDtl {
    color: #646464;
}

.liveAuction .actnTmrDtl .acTimer .material-icons {
    font-size: 60px;
}

.liveAuction .upldBlkWrpr {
    background: #fcfdff;
    border: 1px solid #e4edff;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.liveAuction .upldBlkWrpr .lftCtnr p {
    color: var(--secColor);
}

.liveAuction .upldBlkWrpr .lftCtnr h3 {
    font-size: 18px;
    font-weight: 600;
}

.liveAuction .upldBlkWrpr .mnfstBtn {
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;
    color: var(--primColor);
}

.liveAuction .upldBlkWrpr .mnfstBtn.upldBdnBtn {
    border: 1px solid var(--primColor);
    background: white;
}

.liveAuction .headerSearchForm {
    background: #fff;
    border-radius: 25px;
    height: 45px;
    width: 320px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    /* margin-inline-start: 20px; */
    border: 1px solid #e4e4e4;
}

.liveAuction .headerSearchForm input {
    border: none;
    margin-inline-end: 0;
    height: inherit;
    background: inherit;
    border-radius: 25px;
    /* border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    padding: 12px 25px 12px 25px;
    color: #848484;
    width: -webkit-fill-available;
    background: transparent;
}

.liveAuction .headerSearchForm button {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secColor);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    min-width: 45px;
    margin-inline-end: 0px;
}

.liveAuction .headerSearchForm input:focus,
.liveAuction .headerSearchForm button:focus {
    border: none;
    box-shadow: none;
}

.liveAuction .headerSearchForm input::placeholder {
    color: #848484;
}

.lotPreview .MuiButton-label {
    color: var(--primColor);
}

.productView .closeIcon {
    min-width: max-content;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.lotPreview {
    margin-top: 10px;
    width: 100%;
}

.lotPreview .MuiButton-label .material-icons-outlined {
    padding-left: 10px;
}

.productView .lctnWrpr .lctnDtls {
    flex-wrap: wrap;
}
.productView .lctnWrpr .lctnDtls p {
    width: 100%;
    margin-bottom: 8px;
}

.lctnDtls .material-icons-outlined {
    padding-right: 10px;
}

.fxdLable {
    min-width: 150px;
    color: gray;
}

@media (min-width: 1500px) {
    .standalonePage.productView .pvLt {
        width: 40%;
    }
    .standalonePage.productView .pvRt {
        width: 60%;
    }
}

@media (max-width: 1024px) {
    .productView .pvBidBox form {
        width: 100%;
    }

    .bdPlcWrpr {
        flex-wrap: wrap;
    }

    .productView .pvBidBox form {
        margin-left: 0;
    }

    .actnDtlWrpr .acContent h2 {
        margin: 20px 0;
    }

    .liveAuction .upldBlkWrpr {
        flex-wrap: wrap;
        justify-content: center;
    }

    .liveAuction .upldBlkWrpr .lftCtnr {
        margin-bottom: 15px;
    }

    .liveAuction .upldBlkWrpr .lftCtnr p {
        text-align: center;
    }

    .standalonePage.productView .pvRt {
        padding-left: 15px;
    }
}

@media (max-width: 767px) {
    .standalonePage.productView .pvLt,
    .standalonePage.productView .pvRt {
        width: 100%;
    }

    .standalonePage.productView .pvRt {
        padding-left: 0;
    }

    .productView .pvBidBox .biddingCnt .secButton button {
        min-width: unset;
        white-space: pre-wrap;
    }
}

@media (max-width: 500px) {
    .productView .pvBidBox {
        width: 100%;
    }
    .productView .cartHead {
        font-size: 18px;
        color: #353535;
        font-weight: 700;
        margin: 20px 0 10px 0;
        text-transform: uppercase;
    }
    .productView .pvBidBox form {
        width: 100%;
    }
    .productView .pvBidBox form:nth-child(2) {
        margin-top: 15px;
    }

    .productView .pvBidBox form {
        width: 100%;
    }
    .productView .lctnWrpr,
    .productView .lctnDtls {
        flex-wrap: wrap;
    }

    .productView .lctnDtls p {
        margin: 10px 0;
    }

    .standalonePage.productView .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 300px;
    }

    .standalonePage.productView .image-gallery-thumbnail .image-gallery-thumbnail-image {
        height: 60px;
        object-fit: cover;
        border-radius: 5px;
    }
}

@media (max-width: 420px) {
    .productView .pvBidBox .biddingCnt {
        flex-wrap: wrap;
    }
    .productView .pvBidBox .biddingCnt .customInput {
        /* width: 100%; */
        margin-bottom: 15px;
    }

    .similarItem .pclImg img {
        height: 90px;
    }
    .productView .pvBidStatus h4 {
        font-size: 13px;
    }
}
