.bdInfoTxt {
    color: #a0a0a0;
    font-size: 12px;
}

.adTempltPopup .fspBody .MuiAppBar-root {
    box-shadow: none;
    margin-bottom: 15px;
}

.adTempltPopup .fspBody .MuiAppBar-root .MuiTabs-indicator {
    background-color: var(--secColor);
}

.adTempltPopup .fspBody .MuiAppBar-root .MuiTabs-root .MuiTab-root.Mui-selected {
    color: var(--secColor);
}
