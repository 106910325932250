.staticPages {
    min-height: 50vh;
    background: #fff;
    padding: 45px;
    text-align: start;
}
.staticPages h1 {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    color: #4e4e4e;
    margin-bottom: 45px;
    text-align: center;
}

.staticPages h3 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #4e4e4e;
    margin: 45px auto 20px;
    text-align: start;
}

.staticPages p {
    font-size: 16px;
    line-height: 26px;
    color: #333;
}

.staticPages ul li {
    padding-bottom: 10px;
}

@media (max-width: 700px) {
    .privacyWrapper p {
        margin-left: 0 !important;
        white-space: pre-wrap;
    }
}

@media (max-width: 600px) {
    .staticPages {
        padding: 20px;
    }

    .staticPages img {
        width: 100%;
    }

    .staticPages .column-1 {
        width: 100% !important;
    }

    .staticPages .column-1-img {
        width: 30%;
    }

    .stacPgInfoWr {
        width: 100%;
    }

    .stacPgInfoWr > *,
    .staticPages .stacPgInfoWr p {
        font-size: 14px !important;
        line-height: 22px;
    }

    .stacPgInfoWr a {
        word-break: break-all !important;
    }
}
