.login.regContainer {
    width: 100%;
    max-width: 800px;
    padding: 0;
}

.login.regContainer .RadioBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.login.regContainer .RadioBox .radioTitle {
    margin-bottom: 0;
    margin-right: 20px;
}

.login.regContainer .regActBox {
    margin-top: 40px;
}

.login.regContainer .regActBox .primButton {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.login.regContainer .rgTypeWrpr .tabBtns {
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    padding: 10px 30px 10px 10px;
    margin-right: 10px;
    color: #6d6d6d;
}

.login.regContainer .rgTypeWrpr .tabBtns .MuiButton-label {
    text-transform: none;
}

.login.regContainer .rgTypeWrpr .tabBtns .MuiButton-label .material-icons {
    padding-right: 8px;
}

.login.regContainer .rgTypeWrpr .tabBtns.active {
    border: 1px solid var(--primColor);
    background: #f7f8fe;
    color: var(--primColor);
}

@media (max-width: 600px) {
    .login.regContainer .rgTypeWrpr .tabBtns {
        width: 47%;
    }
}

@media (max-width: 475px) {
    .logRegLayout .rtLgContainer {
        width: calc(100vw - 0px);
    }
    .login.regContainer .rgTypeWrpr .tabBtns {
        padding: 10px 10px 10px 10px;
        margin-right: 8px;
    }
}
@media (max-width: 385px) {
}
