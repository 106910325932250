.savedAddressContainer {
    text-align: left;
}

.savedAddressContainer .savedAddress {
    display: grid;
    margin-top: 25px;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
}

.savedAddressContainer .savedAddressCard {
    width: auto;
    height: auto;
    min-height: 160px;
    background: rgb(234 242 255);
    border: 1px solid #e0e0e0;
    padding: 15px 20px;
    border-radius: 5px;
    position: relative;
}

.savedAddressContainer .savedAddressCard.addNew {
    background: #fff;
    border: 2px dashed #dfdfdf;
}

.savedAddressContainer .savedAddressCard h4,
.savedAddressContainer .savedAddressCard address,
.savedAddressContainer .savedCardsCard h4 {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
}

.savedAddressContainer .savedAddressCard address {
    margin: 0;
}

.savedAddressContainer .savedAddressCard .ssActBtn .MuiButton-label {
    text-decoration: underline;
}

.savedAddressContainer > a,
.savedAddressContainer > a {
    font-size: 16px;
    color: #000;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 600;
    color: var(--primColor);
}

.savedAddressContainer .savedAddressCard .dfltCard {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: center;
    background: var(--primColor);
    color: white;
    border-radius: 100px 0 0 100px;
    padding: 5px 10px;
    font-size: 14px;
}
