.productInvoice {
    padding: 2rem !important;
    margin: 30px auto 30px;
    text-align: start;
    font-size: 14px;
}

.productInvoice .invLogo {
    width: 150px;
}

.productInvoice span.material-icons {
    padding-inline-end: 5px;
}

.productInvoice .right {
    text-align: right !important;
}
.nellisInvTot span {
    font-weight: 500;
}

.productInvoice .invoiceView {
    margin-bottom: 30px;
    border: none;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    border-top: 4px solid var(--primColor);
    background: #fff;
}

.productInvoice .card-header {
    background-color: #fff;
    border-bottom: 1px solid #e6e6f2;
}

.productInvoice .invoiceView h3 {
    font-size: 20px;
}

.productInvoice .invoiceTitle {
    font-size: 18px;
    margin: 0px 0 15px 0;
    font-weight: 600;
    color: #3a3a3a;
    text-align: center;
    text-transform: uppercase;
}

.productInvoice .invoiceSubTitle {
    font-size: 18px;
    margin: 35px 0 15px 0;
    font-weight: 600;
    color: #3a3a3a;
    text-align: start;
    text-transform: uppercase;
}

.productInvoice .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(241, 241, 241);
}
.productInvoice .invoiceView .table thead th {
    font-weight: 600;
    font-size: 15px;
}
.productInvoice .invoiceView .table tbody td {
    font-weight: 400;
    font-size: 15px;
}
.productInvoice .invoiceView h5 {
    font-size: 15px;
    line-height: 26px;
    color: #3d405c;
    margin: 0px 0px 15px 0px;
}

.productInvoice .text-dark {
    color: #3d405c !important;
}
.productInvoice .transDet .invDiv {
    /* width: 25%; */
    margin-bottom: 15px;
}

.productInvoice .transDet table th:last-child,
.productInvoice .transDet table td:last-child {
    text-align: right;
}

.productInvoice .transDet p {
    font-size: 15px;
    margin-bottom: 0px;
    color: #5f5f5f;
}
.productInvoice .transDet span {
    width: 100%;
    font-weight: 500;
}

.productInvoice .cartItem {
    padding: 0;
    margin-bottom: 15px;
}

.productInvoice .thankText {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #7b7b7b;
    letter-spacing: 0.5px;
}

.productInvoice .transDet table {
    width: 100%;
    border-collapse: collapse;
}
/* Zebra striping */
.productInvoice .transDet table tr:nth-of-type(odd) {
    background: #fff;
    margin-bottom: 10px;
}
.productInvoice .transDet table th {
    background-color: #333;
    color: white;
    font-weight: bold;
}
.productInvoice .transDet table td,
.productInvoice .transDet table th {
    padding: 6px;
    border: 1px solid #e6e6e6;
    text-align: start;
}

@media only screen and (max-width: 767px) {
    .nellisInvTot tbody td {
        width: 50%;
        display: inline-block;
    }
    .productInvoice .invoiceView .table tbody td {
        padding: 10px;
    }
    .productInvoice {
        margin: 15px auto 15px;
    }

    .productInvoice .transDet table {
        border: none;
    }
    .productInvoice .transDet table td,
    .productInvoice .transDet table th {
        text-align: right;
    }

    /* Force table to not be like tables anymore */
    .productInvoice .transDet table,
    .productInvoice thead,
    .productInvoice tbody,
    .productInvoice th,
    .productInvoice td,
    .productInvoice tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .productInvoice .transDet thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    /* .productInvoice .transDet tr {
        border: 1px solid #ccc;
    } */

    .productInvoice .transDet td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-inline-start: 50%;
    }

    .productInvoice .transDet td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 9px;
        left: 10px;
        width: 45%;
        padding-inline-end: 10px;
        white-space: nowrap;
        content: attr(data-title) ':';
        text-align: start;
        font-weight: 600;
        color: #000;
    }

    td.transcationIdCnt {
        padding-left: 120px !important;
        word-break: break-all !important;
    }
}

@media (max-width: 500px) {
    .productInvoice .invoiceView h5 {
        margin-bottom: 5px !important;
    }

    .invDate {
        text-align: center;
        margin-top: 10px;
    }

    .card-body .invAddr {
        text-align: start !important;
    }

    .card-body .invAddr:last-child {
        margin-top: 20px;
    }
    .productInvoice .invoiceSubTitle {
        font-size: 16px;
        margin-top: 25px !important;
    }
    .productInvoice .transDet td:before {
        font-size: 14px;
    }
    .cartItem .listContent .listProdTitle {
        font-size: 15px;
    }
    .productInvoice {
        padding: 15px !important;
    }
    .card-header {
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .card-header a {
        margin: 0 auto;
    }

    .card-body > div {
        /* width: 100%; */
        float: none;
        text-align: center;
        margin-top: 10px;
    }
}

@media print {
    .productInvoice .transDet table th {
        background-color: #fff !important;
        color: #333 !important;
    }
}
