.homeWrapper .topMainBanner {
    min-height: 900px;
    z-index: 1;
    position: relative;
}

.homeWrapper .topMainBanner .customContainer {
    position: relative;
    z-index: 0;
}

.homeWrapper .topMainBanner .bannerImage {
    width: 80%;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: -1;
}

.homeWrapper .topMainBanner .bnrTxtCntnr {
    padding-top: 330px;
    z-index: 1;
    color: white;
}

.homeWrapper .topMainBanner .bnrTxtCntnr h4 {
    font-size: 60px;
    font-weight: 600;
}

.homeWrapper .topMainBanner .bnrTxtCntnr p {
    font-size: 22px;
}

.homeWrapper .topMainBanner .bnrTxtCntnr .hlTxt {
    padding: 10px 30px;
    background: var(--secColor);
    width: max-content;
    border-radius: 10px;
    margin: auto;
}

.homeWrapper .topMainBanner .lapTopImg {
    max-width: 915px;
    width: 100%;
    margin-top: 40px;
}

.homeWrapper .wyBdSft {
    position: relative;
    z-index: 0;
}

.homeWrapper .wyBdSft .customContainer {
    position: relative;
}

.homeWrapper .wyBdSft .whyBck {
    position: absolute;
    top: -245px;
    left: 0;
    right: 0;
    z-index: -1;
    max-width: 1260px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.homeWrapper .cstmHdr {
    font-size: 86px;
    font-weight: 600;
}

.homeWrapper .cstmHdr span {
    font-size: 38px;
    font-weight: normal;
    color: var(--darkFont);
}

.homeWrapper .wyCntWrp {
    padding-top: 90px;
}

.homeWrapper .wyCntWrp .whyGrid {
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1275px;
}

.homeWrapper .wyCntWrp .whyGrid .whyCard {
    background: white;
    padding: 65px 22px;
}

.homeWrapper .wyCntWrp .whyGrid .whyCard h2 {
    font-size: 23px;
    margin-top: 28px;
    margin-bottom: 17px;
}

.homeWrapper .wyCntWrp .whyGrid .whyCard p {
    margin: 0;
    font-size: 14px;
}

.homeWrapper .hwItWksWrpr {
    margin-top: 180px;
}

.homeWrapper .hwItWksWrpr .tabHdCntnr {
    margin-top: 20px;
}

.homeWrapper .hwItWksWrpr .tabHdCntnr {
    margin-top: 20px;
}

.homeWrapper .hwItWksWrpr .tabHdCntnr .MuiTab-root {
    background: #f2f2f2;
    margin: 0 5px;
    height: 62px;
    width: 300px;
    transition: ease-in-out 0.5s;
    text-transform: unset;
    font-size: 22px;
    font-family: var(--primFont);
}

.homeWrapper .hwItWksWrpr .tabHdCntnr .MuiTab-root:first-child {
    border-radius: 10px 0 0 0;
}

.homeWrapper .hwItWksWrpr .tabHdCntnr .MuiTab-root:last-child {
    border-radius: 0 10px 0 0;
}

.homeWrapper .hwItWksWrpr .tabHdCntnr .MuiTab-root.Mui-selected {
    background: #1f243a;
    color: white;
}

.homeWrapper .hwItWksWrpr .tabHdCntnr .MuiTabs-indicator {
    background-color: #1f243a;
}

.homeWrapper .hwItWksWrpr .tbDtlCntnr {
    background: #f2f2f2;
}

.homeWrapper .hwItWksWrpr .tbDtlCntnr .MuiBox-root {
    padding: 110px 0;
}

.homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwFlx {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwCard {
    background: white;
    padding: 58px 20px;
    border: 2px solid var(--primColor);
    border-radius: 11px;
    max-width: 255px;
    min-height: 300px;
}

.homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwCard h2 {
    font-size: 40px;
    margin-bottom: 32px;
}

.homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwCard p {
    font-size: 17px;
    margin: 0;
}

.homeWrapper .hwItWksWrpr .tbDtlCntnr .rtArw {
    margin: 0 25px;
}

.homeWrapper .tstMlWrpr {
    padding-top: 60px;
}

.homeWrapper .tstMlWrpr .tstmlCntnr {
    background: var(--primColor);
    padding: 150px 0;
    position: relative;
}

.homeWrapper .tstMlWrpr .tstmlCntnr::after {
    content: '';
    width: 100%;
    height: 68px;
    clip-path: polygon(0 0%, 0% 100%, 100% 6%);
    background: white;
    position: absolute;
    top: -5px;
    left: 0;
}

.homeWrapper .tstMlWrpr .tstmlCntnr::before {
    content: '';
    width: 100%;
    height: 68px;
    clip-path: polygon(0 93%, 100% 100%, 100% 0);
    background: white;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.homeWrapper .tstMlWrpr .tstmlCntnr .slick-slide {
    opacity: 0.6;
}

.homeWrapper .tstMlWrpr .tstmlCntnr .slick-slide.slick-current {
    opacity: 1;
}

.homeWrapper .tstMlWrpr .tstmlCntnr .tsmlCard {
    color: white;
    padding: 0 30px;
}

.homeWrapper .tstMlWrpr .tstmlCntnr .tsmlCard h4 {
    font-size: 38px;
    height: 218px;
    line-height: 50px;
    font-family: 'PT Serif', serif;
    font-style: italic;
}

.homeWrapper .tstMlWrpr .tstmlCntnr .tsmlCard p {
    font-size: 17px;
}

.homeWrapper .rgstNwRpr {
    padding-top: 80px;
    padding-bottom: 80px;
}

.homeWrapper .rgstNwRpr .qckRegForm {
    max-width: 520px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 51px;
}

.homeWrapper .rgstNwRpr .qckRegForm .customInput .MuiInputBase-root {
    background: white;
}

.homeWrapper .rgstNwRpr .qckRegForm .customInput .MuiOutlinedInput-notchedOutline {
    border: 1px solid #aaaaaa;
    border-radius: 0px;
}

.homeWrapper .rgstNwRpr .qckRegForm .primButton button {
    border-radius: 0;
}

.tstMlWrpr.freeTrial {
    margin-top: 200px;
}

.tstMlWrpr.freeTrial p {
    font-size: 36px;
    color: #fff;
    margin-bottom: 45px;
}

.tstMlWrpr.freeTrial .primButton {
    width: max-content;
    margin: 0 auto;
    min-width: 200px;
}

.homeWrapper .tstMlWrpr.freeTrial .tstmlCntnr {
    padding: 120px 0;
}

@media (max-width: 1024px) {
    .tstMlWrpr.freeTrial {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .homeWrapper .tstMlWrpr .tstmlCntnr .tsmlCard h4 {
        font-size: 24px;
        height: auto;
        min-height: 250px;
        line-height: 36px;
        font-family: 'PT Serif', serif;
        font-style: italic;
    }
    .tstMlWrpr.freeTrial p {
        font-size: 26px;
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwCard {
        padding: 15px 15px;
        max-width: 230px;
        min-height: 200px;
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .rtArw {
        width: 15px;
    }
    .homeWrapper .topMainBanner .bnrTxtCntnr {
        padding-top: calc(100% - 80%);
    }
    .homeWrapper .topMainBanner .bnrTxtCntnr h4 {
        color: #a3a3a3;
    }
    .homeWrapper .topMainBanner .bannerImage {
        width: 100%;
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwCard h2 {
        font-size: 26px;
        margin-bottom: 20px;
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwCard p {
        font-size: 14px;
        margin: 0;
    }
}

@media (max-width: 600px) {
    .homeWrapper .hwItWksWrpr .tabHdCntnr .MuiTab-root {
        width: 150px;
    }
    .homeWrapper .hwItWksWrpr {
        margin-top: 50px;
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .rtArw {
        margin: 25px auto;
        transform: rotate(90deg);
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .MuiBox-root {
        padding: 70px 0;
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwFlx {
        flex-direction: column;
    }
    .homeWrapper .hwItWksWrpr .tbDtlCntnr .hiwCard {
        max-width: 100%;
        min-height: 100%;
        width: calc(100% - 25%);
    }
    .homeWrapper .wyCntWrp .whyGrid .whyCard {
        padding: 30px 22px;
    }
    .homeWrapper .cstmHdr {
        font-size: 65px;
    }
    .homeWrapper .topMainBanner {
        min-height: fit-content;
    }
    .homeWrapper .topMainBanner .bnrTxtCntnr .hlTxt {
        font-size: 16px;
        word-break: break-word;
        width: auto;
    }
    .homeWrapper .topMainBanner .bnrTxtCntnr h4 {
        font-size: 45px;
    }
    .homeWrapper .hwItWksWrpr .tabHdCntnr .MuiTab-root {
        font-size: 18px;
    }
}
@media (max-width: 475px) {
    .homeWrapper .cstmHdr {
        font-size: 38px;
    }
    .homeWrapper .cstmHdr span {
        font-size: 24px;
    }
    .tstMlWrpr.freeTrial p {
        font-size: 18px;
    }
    .homeWrapper .tstMlWrpr.freeTrial .tstmlCntnr {
        padding: 80px 0;
    }
}
