footer {
    background: #1f243a;
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: start;
}

footer .ftrLnks {
    color: white;
}

footer .ftrLnks a {
    color: #dedede;
    font-size: 14px;
    font-weight: normal;
}

footer .ftrLnks h4 {
    font-weight: 600;
    margin-bottom: 20px;
}

footer .ftrLnks ul {
    list-style: none;
    padding-left: 0;
}

footer .ftrLnks .lnkSts {
    margin-left: 90px;
}

footer.homeFooter {
    padding-top: 120px;
    position: relative;
}

.homeFooter::after {
    content: '';
    width: 100%;
    height: 68px;
    clip-path: polygon(0 0%, 0% 100%, 100% 6%);
    background: white;
    position: absolute;
    top: -5px;
    left: 0;
}

@media (max-width: 600px) {
    footer .ftrLnks {
        margin-top: 25px;
    }
    footer .ftrLnks .lnkSts {
        margin-left: 15px;
        margin-right: 15px;
    }
    footer.homeFooter {
        padding-top: 90px;
        padding-bottom: 30px;
    }
    .ftrInrCntnr {
        flex-direction: column;
    }
}

@media (max-width: 425px) {
    footer .ftrLnks .lnkSts {
        margin-left: 10px;
        margin-right: 10px;
    }
    footer .ftrLnks h4 {
        font-size: 14px;
        margin-bottom: 15px;
    }
    footer .ftrLnks a {
        font-size: 12px;
    }
    footer {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
