.search .searchResults .productCardGrid {
    /* margin-inline-end: 5px; */
}

.search .searchCnt .loadingCnt {
    grid-template-columns: 1fr;
}

.search .searchMiscFilters .customInput,
.search .searchMiscFilters .customSelect {
    min-width: 190px;
    max-width: 190px;
}

.search .searchLt .customSelect {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.search .searchLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.search .searchLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.search .searchLt .gridListToggle i {
    padding-inline-end: 10px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 50vmax;
    max-width: 800px;
    padding: 20px;
}

.grdVwCntnr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 30px;
}

.filtersAucType {
    margin-right: auto;
}

.filtersAucType button {
    border-radius: 25px;
}

.filtersAucType button:not(:last-child) {
    margin-right: 15px;
}

.filtersAucType button.active {
    background: var(--darkFont);
    color: #f7f7f7 !important;
}

.searchCnt .listViewSkeleton {
    background: #fff;
}

.searchCnt .skeletonWrapper {
    box-shadow: none;
}

@media (max-width: 1024px) {
    .home .hmSearch .searchInput,
    .search .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .filtersAucType {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: flex-end;
    }

    .searchMiscFilters {
        flex-wrap: wrap;
    }

    .searchLt {
        display: none;
    }

    .sbTitle {
        font-size: 14px;
        color: var(--secColor);
        font-weight: 700;
        text-align: start;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .searchAuctions {
        margin-bottom: 20px;
    }

    .searchAuctions label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        color: var(--primColor);
        height: 50px;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        border: 1px solid #d8d8d8;
        border-radius: 25px;
        margin-inline-end: 10px;
        text-decoration: none;
        transition: 200ms all ease-in-out;
        font-size: 16px;
        font-weight: 600;
    }

    .searchAuctions input[type='radio']:checked + label {
        background: rgb(249 238 236);
        color: var(--primColor);
        border: 1px solid rgb(174 34 7 / 51%);
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 60vmax;
    }

    .productView .lctnWrpr {
        flex-wrap: wrap;
    }

    .productView .lctnWrpr .lctnDtls {
        margin-top: 13px;
    }
}

@media (max-width: 767px) {
    .search .searchMiscFilters {
        flex-wrap: wrap;
    }

    .search .searchMiscFilters .filterSelect {
        width: 50%;
    }

    .search .searchMiscFilters .filterSelect:last-child {
        width: 100%;
        margin-top: 10px;
    }

    .search .searchMiscFilters .customInput,
    .search .searchMiscFilters .customSelect {
        min-width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 600px) {
    .searchAuctions label {
        height: 40px;
        font-size: 14px;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }

    .productView .pvBidBox .customInput,
    .productView .pvBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }

    .productView .pvBidBox .customInput input {
        height: 100%;
    }

    .productView .pvActBtn {
        flex-wrap: wrap;
    }

    .productView .pvActBtn .pabChild:first-child {
        width: 100%;
    }

    .search .searchMiscFilters {
        flex-wrap: wrap;
    }

    .search .searchMiscFilters .filterSelect {
        width: 50%;
    }

    .search .searchMiscFilters .filterSelect:nth-child(2) {
        padding-right: 10px;
    }

    .search .searchMiscFilters .filterSelect:last-child {
        width: 100%;
        margin-top: 10px;
    }

    .search .searchMiscFilters .customInput,
    .search .searchMiscFilters .customSelect {
        width: 100%;
        min-width: unset;
        margin-left: 0;
        max-width: 100%;
        margin: 10px 0;
    }
}

@media (max-width: 445px) {
    .filtersAucType {
        justify-content: center;
    }

    .filtersAucType button {
        font-size: 12px;
    }

    .search .searchMiscFilters .filterSelect {
        width: 100%;
    }

    .search .searchMiscFilters .filterSelect:nth-child(2) {
        padding-right: 0;
    }
}

@media (max-width: 400px) {
    .filtersAucType button:not(:last-child) {
        margin-bottom: 15px;
    }

    .filtersAucType button {
        width: 100%;
        margin-right: 0 !important;
    }
}
