.productPosting {
    background: url(../../../assets/images/dashboardback.png);
    background-size: cover;
    background-position: bottom center;
    min-height: calc(100vh - 316px);
    padding: 40px;
    background-attachment: fixed;
}

.productPosting .hdrCntnr h4 {
    font-size: 24px;
    color: var(--darkFont);
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
}

.productPosting .MuiStepper-root {
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
}

.productPosting .MuiStepper-alternativeLabel {
    background: transparent;
}

.productPosting .MuiStepConnector-lineHorizontal {
    border-top-style: dashed;
    border-color: var(--primColor);
}

.productPosting .stepBodyWrapper {
    background: white;
    padding: 45px 40px;
    border-radius: 10px;
    box-shadow: 0 3px 35px #00000006;
    max-width: 720px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    position: relative;
}

.productPosting .stepBodyWrapper h4 {
    margin-bottom: 40px;
    font-size: 22px;
    font-weight: 600;
}

.productPosting .typeBoxWrpr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.productPosting .typeBoxWrpr .typeBox {
    background: #f6f6f6;
    border-radius: 5px;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid #f6f6f6;
}

.productPosting .typeBoxWrpr .typeBox img {
    width: 90px;
    height: 90px;
    object-fit: scale-down;
    object-position: bottom;
    margin-bottom: 14px;
}

.productPosting .typeBoxWrpr .typeBox.active {
    border: 2px solid var(--primColor);
    background: #edeffc;
}

.productPosting .stepBodyWrapper .btnWrpr {
    margin-top: 70px;
}

.productPosting .stepBodyWrapper .primButton button,
.productPosting .stepBodyWrapper .secButton button {
    width: max-content;
    min-width: 160px;
    margin: 0 13px;
}

.productPosting .stepBodyWrapper p {
    margin: 0;
}

.productPosting .stepBodyWrapper .stpInrDiv .slotEntBtn {
    width: 100%;
    height: 140px;
    border: 1px dashed #cfcfcf;
    color: var(--darkFont);
}

.productPosting .stepBodyWrapper .stpInrDiv .slotEntBtn img {
    margin-bottom: 10px;
}

.productPosting .stepBodyWrapper .stpInrDiv .slotEntBtn .MuiButton-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: none;
}

.productPosting .stepBodyWrapper .stpInrDiv .slotEntBtn p {
    margin: 0;
}

.adSlotPopup.customDialog .primButton button,
.adSlotPopup.customDialog .secButton button {
    min-width: 120px;
}

.productPosting .slotLstWrpr .adNwSltBtn {
    color: var(--primColor);
    background: #f6f7fe;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
}

.productPosting .slotLstWrpr .adNwSltBtn .material-icons-outlined {
    margin-right: 5px;
}

.productPosting .slotLstWrpr .adNwSltBtn .MuiButton-label {
    text-transform: none;
}

.productPosting .dpTable table thead th {
    font-size: 12px;
    color: #5e5e5e;
    font-weight: 400;
}

.productPosting .dpTable table tbody td {
    font-size: 13px;
}

.productPosting .dpTable table tbody td .timSpn {
    color: gray;
}

.productPosting .dpTable table tbody .crncyVl {
    font-size: 16px;
}

.productPosting .dpTable table tbody .icnBtn {
    background: #f6f6f6;
}

.productPosting .dpTable table tbody .customCheckbox .MuiButtonBase-root .MuiIconButton-label {
    color: var(--darkFont);
}

.productPosting .stepBodyWrapper .postImg {
    position: absolute;
    bottom: 0;
    right: -260px;
    width: 260px;
}

.templatePopupModal .tmpltsWrppr {
    height: 70vh;
    overflow: auto;
    max-width: 900px;
    background: white;
    padding: 45px 40px;
    border-radius: 10px;
    box-shadow: 0 3px 35px #00000006;
    margin-inline-start: auto;
    margin-inline-end: auto;
    position: relative;
}

.templatePopupModal .MuiDialog-paperFullScreen {
    background: url(../../../assets/images/dashboardback.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
}

.templatePopupModal .slctTmpTtle {
    font-size: 24px;
    color: var(--darkFont);
    text-transform: uppercase;
    margin-bottom: 40px;
    font-weight: 600;
    text-align: center;
}

.templatePopupModal.fullScreenPopup .MuiPaper-root.MuiAppBar-root {
    background-color: transparent !important;
    box-shadow: none;
}

.templatePopupModal.fullScreenPopup .MuiPaper-root.MuiAppBar-root .MuiIconButton-label {
    color: black;
}

.templatePopupModal .tmpltsWrppr .table-responsive {
    height: 100%;
}

.templatePopupModal .tmpltsWrppr .table-responsive thead {
    position: sticky;
    top: 0;
    z-index: 999;
}

.productPosting .stepBodyWrapper p.subscrbInfo {
    color: var(--secColor);
    font-weight: 500;
    font-size: 13px;
    margin-top: 5px;
}

.productPosting .miscActions .adNwSltBtn {
    color: var(--primColor);
    background: #f6f7fe;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
}

@media (max-width: 1250px) {
    .productPosting .stepBodyWrapper .postImg {
        right: -130px;
    }
}

@media (max-width: 992px) {
    .productPosting .stepBodyWrapper .postImg {
        display: none;
    }
}

@media (max-width: 600px) {
    .productPosting .stepBodyWrapper .primButton button,
    .productPosting .stepBodyWrapper .secButton button {
        min-width: 115px;
    }
    .productPosting {
        padding: 40px 15px;
    }
    .productPosting .typeBoxWrpr .typeBox img {
        width: 65px;
        height: 65px;
    }
    .productPosting .stepBodyWrapper {
        padding: 40px 25px;
    }
    .productPosting .typeBoxWrpr {
        gap: 10px;
    }
    .productPosting .MuiStepper-alternativeLabel {
        background: transparent;
        padding: 15px 5px;
        max-width: 100%;
    }
    .productPosting .stepBodyWrapper p {
        font-size: 14px;
    }
    .productPosting .stepBodyWrapper h4 {
        margin-bottom: 25px;
        font-size: 18px;
    }
}

@media (max-width: 475px) {
    .productPosting .typeBoxWrpr .typeBox img {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 410px) {
    .productPosting .MuiStepper-alternativeLabel {
        zoom: 0.6;
    }
}
