.templateTable .actnCol {
    width: 220px;
}

.templateTable .descFld {
    color: #9c9c9c;
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.formActionButtons button {
    width: 180px;
    margin-left: 10px;
}
